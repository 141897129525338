import React from "react";
import PropTypes from "prop-types";
import {Skeleton} from "@mui/material";

export const StyledSkeleton = ({
  animation = "wave",
  variant = "rectangular",
  ...others
}) => (
  <Skeleton
    animation={animation}
    variant={variant}
    style={{borderRadius: 0}}
    {...others}
  />
);

StyledSkeleton.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  others: PropTypes.any,
};

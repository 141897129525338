import {makeStyles} from "tss-react/mui";
import {breachRxColor} from "../../resources/theme";

export const useIntegrationStyles = makeStyles()((theme) => ({
  card: {
    width: 200,
    height: 80,
    position: "relative",
    backgroundColor: theme.palette.draggable.accent,
    border: `1.5px solid ${theme.palette.grey[theme.palette.mode === "dark" ? "800" : "400"]}`,
    "&:hover": {
      cursor: "pointer",
      border: `1.5px solid ${breachRxColor}`,
    },
    "@media print": {
      height: "75px",
    },
  },
  attribute: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  attributeNoBorder: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 0,
  },
  logo: {
    maxWidth: 125,
    maxHeight: 80,
  },
  logoDetail: {
    height: 100,
    width: 100,
    marginRight: "1.5em",
  },
  noPrint: {
    "@media print": {
      display: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-around",
  },
  link: {
    marginLeft: ".5em",
  },
  isEnabledIcon: {
    position: "absolute",
    top: ".25em",
    right: ".25em",
    color: breachRxColor,
  },
  connectionInstructions: {
    marginTop: "1em",
  },
  copyGridWrapper: {
    display: "grid",
    gridTemplateColumns: "min-content min-content",
    gap: theme.spacing(1),
    alignItems: "baseline",
    justifyItems: "start",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

import React, {createContext, useEffect, useState, useContext} from "react";
import PropTypes from "prop-types";

const FeatureFlagContext = createContext({setFeatureFlags: () => {}});

export const FeatureFlagProvider = ({children}) => {
  const [featureFlags, setFeatureFlags] = useState(false);

  useEffect(async () => {
    setFeatureFlags({
      showDeveloper: process.env.DEVELOPER_FLAG === "true",
      showGettingStarted: true,
      setFeatureFlags,
    });
  }, []);

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFeatureFlags = () => {
  return useContext(FeatureFlagContext);
};

import {gql} from "@apollo/client";

const CORE_ORGANIZATION_SETTING_FIELDS = gql`
  fragment CoreOrganizationSettingFields on OrganizationSetting {
    id
    key
    value
    displayName
    description
  }
`;

const GET_ORGANIZATION_SETTING = gql`
  ${CORE_ORGANIZATION_SETTING_FIELDS}
  query GetOrganizationSetting($settingKey: String!) {
    organizationSettings(where: {key: {equals: $settingKey}}) {
      ...CoreOrganizationSettingFields
    }
  }
`;

const GET_ORGANIZATION_SETTINGS = gql`
  ${CORE_ORGANIZATION_SETTING_FIELDS}
  query GetOrganizationSettings {
    organizationSettings(orderBy: {id: asc}) {
      ...CoreOrganizationSettingFields
    }
  }
`;

const GET_SOME_ORGANIZATION_SETTINGS = gql`
  ${CORE_ORGANIZATION_SETTING_FIELDS}
  query GetSomeOrganizationSettings($settingKeys: [String!]) {
    organizationSettings(where: {key: {in: $settingKeys}}, orderBy: {id: asc}) {
      ...CoreOrganizationSettingFields
    }
  }
`;

const GET_ACTION_VISIBILITY_OPTIONS = gql`
  query GetActionVisibilityOptions {
    actionVisibilityOptions: __type(name: "ActionVisibility") {
      enumValues {
        name
      }
    }
  }
`;

const GET_ORGANIZATION_ATTRIBUTES = gql`
  query GetOrganizationAttributes {
    organizationAttributes {
      id
      condition {
        id
        field
        fieldType
        listOptions {
          id
          name
          description
        }
      }
      value
    }
  }
`;

const UPDATE_ORGANIZATION_ATTRIBUTES = gql`
  mutation UpdateOrganizationAttributes(
    $attributesToCreate: [OrganizationAttributeCreateInput!]
    $attributesToDelete: [Int!]
  ) {
    updateOrganizationAttributes(
      attributesToCreate: $attributesToCreate
      attributesToDelete: $attributesToDelete
    ) {
      id
      condition {
        id
        field
        fieldType
        listOptions {
          id
          name
          description
        }
      }
      value
    }
  }
`;

const UPDATE_ORGANIZATION_SETTING = gql`
  ${CORE_ORGANIZATION_SETTING_FIELDS}
  mutation UpdateOrganizationSetting(
    $id: OrganizationSettingWhereUniqueInput!
    $value: String!
  ) {
    updateOneOrganizationSetting(where: $id, data: {value: {set: $value}}) {
      ...CoreOrganizationSettingFields
    }
  }
`;

const GET_ORGANIZATION_REGULATIONS = gql`
  ${CORE_ORGANIZATION_SETTING_FIELDS}
  query GetOrganizationSettings {
    organizationSettings(
      where: {
        OR: [
          {key: {equals: "GLBARegulated"}}
          {key: {equals: "HIPAARegulated"}}
          {key: {equals: "InteragencyGuidanceEntity"}}
        ]
      }
    ) {
      ...CoreOrganizationSettingFields
    }
  }
`;

export {
  GET_ACTION_VISIBILITY_OPTIONS,
  GET_ORGANIZATION_SETTINGS,
  GET_SOME_ORGANIZATION_SETTINGS,
  UPDATE_ORGANIZATION_SETTING,
  GET_ORGANIZATION_SETTING,
  GET_ORGANIZATION_REGULATIONS,
  GET_ORGANIZATION_ATTRIBUTES,
  UPDATE_ORGANIZATION_ATTRIBUTES,
};

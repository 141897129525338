import {isEmpty} from "underscore";

export const escapeRegExp = (value) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

export const relationSymbol = (relation) => {
  switch (relation) {
    case "eq":
      return "= (equals)";
    case "in":
      return `${String.fromCharCode(8715)} (includes)`;
    case "gt":
      return "> (greater than)";
    case "gte":
      return `${String.fromCharCode(8805)} (greater than or equal to)`;
    case "lt":
      return "< (less than)";
    case "lte":
      return `${String.fromCharCode(8804)} (less than or equal to)`;
    case "AND":
      return "All";
    case "OR":
      return "Any";
  }
};

export const isEmptyHTML = (text) => {
  const clean = text
    ?.replace(/(<([^>]+)>)/gi, "")
    .replace(/&nbsp;/gi, "")
    .replace(/\s/g, "");

  return clean === "" || isEmpty(clean);
};

export const isStringValidURL = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const toBoolean = (val) => {
  if (
    val === true ||
    (typeof val === "string" && val.toLowerCase() === "true")
  ) {
    return true;
  }

  return false;
};

import {Subject} from "rxjs";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import DOMPurify from "dompurify";

// visual constants
export const sidebarWidth = {open: 225, collapsed: 80};
export const sidebarClass = "sidebar-hidden";
export const stoplightRange = {error: 30, warning: 60};
export const userIdleTimeoutInMilliseconds = 1000 * 60 * 30; // 30 min

// fixed paths
export const shield = require("~/public/shield-logo.png");
export const US = require("~/public/geographies/states-10m.json");
export const world = require("~/public/geographies/world-110m.json");
export const statusPage = "https://breachrx.statuspage.io/";

// marketing tag lines
export const tagline = "Dynamic Readiness & Response Platform";
export const consoleTagline =
  "https://breachrx.com - Prepare, respond, recover.";

export const recoveryCodeMessage =
  "A code has been sent to the provided email address if it " +
  "exists.\n Please enter that recovery code below.";

export const phoneFormatError =
  "Phone number not in a valid international format, such as " +
  "+17031234567, including country code";

// alert message bus
const messageBus = new Subject().pipe();
export const ALERT = {
  messages: messageBus,
  success: (text) => messageBus.next([{type: "success", text}]),
  warning: (text) => messageBus.next([{type: "warning", text}]),
  error: (text) => messageBus.next([{type: "error", text}]),
  info: (text) => messageBus.next([{type: "info", text}]),
  redirect: new Subject(),
  duration: 2500,
  maxSnack: 5,
};

// dayjs plugin initialization
export const initializeDayJS = () => {
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(quarterOfYear);
};

export const initializeDOMPurify = () => {
  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    if (node.hasAttribute("href")) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
    if (node.hasAttribute("style")) {
      const styleValue =
        node.attributes?.["style"]?.value ?
          node.attributes?.["style"]?.value
            ?.replace(/url/gi, "")
            .replace(/javascript/gi, "") :
          "";
      node.setAttribute("style", styleValue);
    }
  });
};

import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";

const TopbarMenuItem = forwardRef(({icon, id, onClick, text}, ref) =>
  !icon || !id || !onClick || !text ?
    null :
    <MenuItem onClick={onClick} id={id} ref={ref}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </MenuItem>,
);

TopbarMenuItem.propTypes = {
  icon: PropTypes.any,
  id: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default TopbarMenuItem;

import React, {createContext, useState, useContext, useEffect} from "react";
import {PropTypes} from "prop-types";
import {
  TourProvider as ReactTourProvider,
  useTour as useReactTour,
} from "@reactour/tour";
import {Checkbox, Typography} from "@mui/material";
import ConfirmationModal, {
  useConfirmationModal,
} from "../../modals/ConfirmationModal";
import {useWizardStyles} from "../../wizard/wizardStyles";
import {useTourStorage} from "../useTourStorage";

const TourContext = createContext(null);

const TourModal = ({confirm, onTourClose, allowPermanentPause}) => {
  const [isPermanent, setIsPermanent] = useState(false);
  const {setIsOpen} = useReactTour();
  const {tourActive, setTourPaused, pauseOnClose} = useTour();
  const closeModalText = `Are you sure you want to ${
    pauseOnClose ? "pause the guide?" : "stop the tour?"
  }`;

  const closeTour = () => {
    if (tourActive && pauseOnClose) setTourPaused(true, isPermanent);
    onTourClose();
    setIsOpen(false);
  };

  const handleCheck = () => setIsPermanent((isChecked) => !isChecked);

  const permanentPauseSelector = (
    <span style={{display: "flex"}}>
      <Checkbox
        checked={isPermanent}
        onChange={handleCheck}
        sx={{borderRadius: 0}}
      />
      <Typography
        variant="caption"
        onClick={handleCheck}
        sx={{cursor: "pointer", marginTop: 1.5}}
      >
        Permanently pause this guide. It can be resumed from the upper right
        menu in the future.
      </Typography>
    </span>
  );

  return (
    <ConfirmationModal
      open={confirm.isOpen}
      onClose={() => confirm.setClosed()}
      onAgree={closeTour}
      title={closeModalText}
      overTour
    >
      {allowPermanentPause && permanentPauseSelector}
    </ConfirmationModal>
  );
};

TourModal.propTypes = {
  confirm: PropTypes.object,
  onTourClose: PropTypes.func,
  allowPermanentPause: PropTypes.bool,
  pauseOnClose: PropTypes.bool,
};

export const TourProvider = ({children}) => {
  const {classes} = useWizardStyles();
  const confirm = useConfirmationModal();
  const [config, setConfig] = useState({});
  const [tourId, setTourId] = useState(null);
  const [tourActive, setTourActive] = useState(false);
  const [tourPaused, setTourPaused] = useState({});
  const [pauseOnClose, setPauseOnClose] = useState(false);
  const [onTourClose, setOnTourClose] = useState(() => () => {});
  const {
    tourLoaded,
    setTourLoaded,
    completedSections,
    tourPermanentlyPaused,
    setCompletedSections,
    loadCompletedSections,
    addCompletedSection,
    saveProgress,
    permanentlyPauseTour,
    resetTourProgress,
    isUpdatingUserMetadata,
  } = useTourStorage({tourId});

  const onClickMask = () => {
    confirm.setOpen();
  };

  useEffect(() => {
    if (tourLoaded) setTourLoaded(false);
    if (tourPermanentlyPaused) {
      setTourPaused((current) => ({...current, [tourId]: true}));
    }
    if (tourId) loadCompletedSections();
    else {
      setTourActive(false);
      setConfig({});
      setCompletedSections([]);
    }
  }, [tourId]);

  useEffect(() => {
    if (tourActive) {
      saveProgress();
    }
  }, [completedSections]);

  const pauseTour = async (event, isPermanent) => {
    await permanentlyPauseTour(isPermanent);
    setTourPaused((tourPaused) => ({...tourPaused, [tourId]: event}));
  };

  return (
    <ReactTourProvider
      steps={[]}
      scrollSmooth
      disableInteraction
      disableDotsNavigation
      onClickMask={onClickMask}
      onClickClose={onClickMask}
      className={classes.tourProviderPopover}
      {...config}
    >
      <TourContext.Provider
        value={{
          setTourId,
          tourId,
          setOnTourClose,
          setConfig,
          addCompletedSection,
          completedSections,
          tourLoaded,
          tourActive,
          tourPaused: !!tourPaused?.[tourId],
          setTourPaused: pauseTour,
          setTourActive,
          pauseOnClose,
          setPauseOnClose,
          resetTourProgress,
          isUpdatingUserMetadata,
        }}
      >
        {children}
        <TourModal
          confirm={confirm}
          onTourClose={onTourClose}
          allowPermanentPause={["exercise"].includes(tourId)}
        />
      </TourContext.Provider>
    </ReactTourProvider>
  );
};

TourProvider.propTypes = {
  children: PropTypes.any,
  tourId: PropTypes.string,
};

export const useTour = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error("Context must be used within the correct provider");
  }
  return context;
};

import React from "react";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {ALERT} from "../resources/environment";
import {SnackbarProvider} from "notistack";

const useAlertProviderStyles = makeStyles()((theme) => ({
  alertRoot: {
    "& div": {
      borderRadius: "0 !important",
    },
  },
  containerRoot: {
    "& div div": {
      float: "right",
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
        paddingLeft: theme.spacing(0.5),
      },
      "& button": {
        minWidth: "unset",
      },
    },
  },
  success: {
    borderRadius: 0,
    color: "rgb(30, 70, 32) !important",
    backgroundColor: "rgb(237, 247, 237) !important",
    "& div svg": {
      color: theme.palette.success.main,
    },
    "& div button svg": {
      color: "rgb(30, 70, 32)",
    },
  },
  warning: {
    borderRadius: 0,
    color: "rgb(102, 60, 0) !important",
    backgroundColor: "rgb(255, 244, 229) !important",
    "& div svg": {
      color: theme.palette.warning.main,
    },
    "& div button svg": {
      color: "rgb(102, 60, 0)",
    },
  },
  error: {
    borderRadius: 0,
    color: "rgb(97, 26, 21) !important",
    backgroundColor: "rgb(253, 236, 234) !important",
    "& div svg": {
      color: theme.palette.error.main,
    },
    "& div button svg": {
      color: "rgb(97, 26, 21)",
    },
  },
  info: {
    borderRadius: 0,
    color: "rgb(13, 60, 97) !important",
    backgroundColor: "rgb(232, 244, 253) !important",
    "& div svg": {
      color: theme.palette.info.main,
    },
    "& div button svg": {
      color: "rgb(13, 60, 97)",
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const AlertProvider = ({children, maxSnack}) => {
  const {classes} = useAlertProviderStyles();
  const alertRef = React.createRef();

  return (
    <SnackbarProvider
      ref={alertRef}
      maxSnack={maxSnack || ALERT.maxSnack}
      preventDuplicate
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      autoHideDuration={ALERT.duration}
      action={(key) => (
        <Button
          onClick={() => alertRef.current.closeSnackbar(key)}
          color="grey"
        >
          <CloseIcon />
        </Button>
      )}
      iconVariant={{
        success: <CheckCircleOutlinedIcon className={classes.icon} />,
        error: <ErrorOutlineOutlinedIcon className={classes.icon} />,
        warning: <ReportProblemOutlinedIcon className={classes.icon} />,
        info: <InfoOutlinedIcon className={classes.icon} />,
      }}
      classes={{
        root: classes.alertRoot,
        containerRoot: classes.containerRoot,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
        variantError: classes.error,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.any.isRequired,
  maxSnack: PropTypes.number,
};

export default AlertProvider;

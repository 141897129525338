import {isEmpty, isObject} from "underscore";

export const deepFindValueForKey = (object, keyToFind, depth = 0) => {
  if (!isObject(object) || isEmpty(keyToFind) || depth > 10) return undefined;
  if (object?.[keyToFind]) return object[keyToFind];

  for (const key in object) {
    if (isObject(object[key])) {
      const value = deepFindValueForKey(object[key], keyToFind, depth + 1);
      if (value) return value;
    }
  }

  return undefined;
};

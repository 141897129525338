import {gql} from "@apollo/client";

export const GET_SALESFORCE_INTEGRATION_SETTINGS = gql`
  query GetSalesforceClientId {
    getSalesforceClientId
  }
`;

export const SET_SALESFORCE_CLIENT_DETAILS = gql`
  mutation SetSalesforceClientSecrets(
    $clientId: String!
    $clientSecret: String!
  ) {
    setSalesforceClientDetails(
      data: {clientId: $clientId, clientSecret: $clientSecret}
    ) {
      name
      configuration
    }
  }
`;

export const UPDATE_SALESFORCE_INTEGRATION = gql`
  mutation UpdateSalesforceIntegration(
    $contactFieldNameTrigger: String!
    $condition: ConditionWhereUniqueInput
    $isEnabled: Boolean
  ) {
    updateSalesforceIntegration(
      data: {
        contactFieldNameTrigger: $contactFieldNameTrigger
        condition: $condition
        isEnabled: $isEnabled
      }
    ) {
      name
      configuration
    }
  }
`;

export const DELETE_SALESFORCE_INTEGRATION = gql`
  mutation DeleteSalesforceIntegration {
    deleteSalesforceIntegration {
      name
      configuration
    }
  }
`;

export const SET_SALESFORCE_ACCESS_TOKEN = gql`
  mutation SetSalesforceAccessTokens($code: String!) {
    setSalesforceAccessTokens(code: $code) {
      name
      configuration
    }
  }
`;

import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {Avatar, Button, Divider, IconButton, Tooltip} from "@mui/material";
import {
  Brightness2,
  ExpandMore,
  NetworkCheck,
  Person,
  PowerSettingsNew,
  WbSunny,
} from "@mui/icons-material";
import StyledTopbarMenu from "./StyledTopbarMenu";
import TopbarMenuItem from "./TopbarMenuItem";
import {useTheme} from "../../context/ThemeWrapperProvider";
import {useUser} from "../user/Context/UserProvider";
import {useSidebar} from "../sidebar/Context/SidebarProvider";
import {useAuth} from "../authentication/Context/AuthProvider";
import {statusPage} from "../../resources/environment";
import {useTopbarStyles} from "./topbarStyles";

export const TopbarProfile = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const {user} = useUser();
  const {setCurrentPage} = useSidebar();
  const navigate = useNavigate();
  const {classes} = useTopbarStyles();
  const auth = useAuth();
  const {activeTheme, toggleActiveTheme} = useTheme();

  const isLightMode = activeTheme?.palette?.mode === "light";

  const toggleMenu = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const logOut = async () => {
    await auth.logout();
  };

  return (
    <>
      <Tooltip title={user.displayName} disableInteractive>
        <Button
          className={classes.topbarIconButton}
          aria-label="account"
          id="account"
          startIcon={
            <Avatar
              className={classes.avatar}
              aria-label={user.displayName}
              id="current-user-avatar"
              src={user?.profileDocumentPreview}
            >
              {user?.displayName?.charAt(0)}
            </Avatar>
          }
          component={NavLink}
          to="/userProfile"
        ></Button>
      </Tooltip>
      <IconButton
        edge="end"
        className={classes.topbarIconButton}
        aria-label="menu"
        id="toggle-menu"
        onClick={toggleMenu}
        size="large"
      >
        <ExpandMore className={classes.topbarButton} />
        <StyledTopbarMenu
          anchorEl={anchorEl}
          id="account-menu"
          keepMounted
          open={!!anchorEl}
          onClose={closeMenu}
        >
          <TopbarMenuItem
            text="Manage Profile"
            onClick={() => {
              navigate("/userProfile");
              setCurrentPage("/userProfile");
            }}
            icon={<Person fontSize="small" />}
            id="manage-profile"
          />
          <Divider />
          <TopbarMenuItem
            text={`${isLightMode ? "Dark" : "Light"} Mode`}
            onClick={toggleActiveTheme}
            icon={
              <>
                {isLightMode && <Brightness2 fontSize="small" />}
                {!isLightMode && <WbSunny fontSize="small" />}
              </>
            }
            id="switch-theme"
          />
          <Divider />
          <TopbarMenuItem
            text="BreachRx Status"
            onClick={() => window.open(statusPage)}
            icon={<NetworkCheck fontSize="small" />}
            id="status"
          />
          <Divider />
          <TopbarMenuItem
            text="Logout"
            onClick={logOut}
            icon={<PowerSettingsNew fontSize="small" />}
            id="logout"
          />
        </StyledTopbarMenu>
      </IconButton>
    </>
  );
};

import {gql} from "@apollo/client";

const CORE_ROLE_FIELDS = gql`
  fragment CoreRoleFields on Role {
    id
    name
    description
    isDeleted
    isEnabled
  }
`;

const EXTENDED_ROLE_FIELDS = gql`
  ${CORE_ROLE_FIELDS}
  fragment ExtendedRoleFields on Role {
    ...CoreRoleFields
    users {
      id
      isDeleted
      userId
      roleId
      user {
        id
        email
        fullName
        isDeleted
        isEnabled
        roles {
          id
          roleId
          role {
            id
            name
            isEnabled
            isDeleted
          }
        }
      }
    }
    tags {
      id
      isDeleted
      tag {
        id
        isDeleted
        key
        value
      }
    }
  }
`;

const LIST_ROLES = gql`
  ${CORE_ROLE_FIELDS}
  query GetRoles($where: RoleWhereInput) {
    roles(where: $where) {
      ...CoreRoleFields
    }
  }
`;

const LIST_ROLES_EXTENDED = gql`
  ${EXTENDED_ROLE_FIELDS}
  query GetRolesExtended {
    roles {
      ...ExtendedRoleFields
    }
  }
`;

const ADD_ROLE = gql`
  ${EXTENDED_ROLE_FIELDS}
  mutation CreateRole(
    $name: String!
    $description: String
    $users: [UserRoleCreateManyRoleInput!]!
    $tagsToCreate: [RoleTagCreateManyRoleInput!]!
  ) {
    createOneRole(
      data: {
        name: $name
        description: $description
        users: {createMany: {data: $users}}
        tags: {createMany: {data: $tagsToCreate}}
      }
    ) {
      ...ExtendedRoleFields
    }
  }
`;

const UPDATE_ROLE = gql`
  ${EXTENDED_ROLE_FIELDS}
  mutation UpdateRole(
    $id: Int!
    $name: String!
    $description: String
    $usersToDelete: [Int!]
    $usersToCreate: [UserRoleCreateManyRoleInput!]!
    $tagsToCreate: [RoleTagCreateManyRoleInput!]!
    $tagsToDelete: [Int!]
  ) {
    updateOneRole(
      where: {id: $id}
      data: {
        name: {set: $name}
        description: {set: $description}
        users: {
          deleteMany: {userId: {in: $usersToDelete}}
          createMany: {data: $usersToCreate}
        }
        tags: {
          deleteMany: {tagId: {in: $tagsToDelete}}
          createMany: {data: $tagsToCreate}
        }
      }
    ) {
      ...ExtendedRoleFields
    }
  }
`;

const GET_ROLE = gql`
  ${EXTENDED_ROLE_FIELDS}
  query GetRole($id: Int!) {
    role(where: {id: $id}) {
      ...ExtendedRoleFields
    }
  }
`;

const UPDATE_ROLE_ENABLEMENT = gql`
  ${EXTENDED_ROLE_FIELDS}
  mutation UpdateRoleEnablement($id: Int!, $isEnabled: Boolean!) {
    updateOneRole(where: {id: $id}, data: {isEnabled: {set: $isEnabled}}) {
      ...ExtendedRoleFields
    }
  }
`;

const SOFT_DELETE_ROLE = gql`
  ${EXTENDED_ROLE_FIELDS}
  mutation SoftDeleteRole($id: Int!) {
    deleteOneRole(where: {id: $id}) {
      ...ExtendedRoleFields
    }
  }
`;

export {
  CORE_ROLE_FIELDS,
  LIST_ROLES,
  LIST_ROLES_EXTENDED,
  ADD_ROLE,
  UPDATE_ROLE_ENABLEMENT,
  SOFT_DELETE_ROLE,
  UPDATE_ROLE,
  GET_ROLE,
};

import React from "react";
import PropTypes from "prop-types";
import {InputAdornment, Link, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import pluralize from "pluralize";
import StyledTooltip from "./tooltips/StyledTooltip";

export const needUpgradeBackground = (theme) =>
  `repeating-linear-gradient(135deg, ${
    theme.palette.grey[theme.palette.mode === "dark" ? "600" : "300"]
  }, ${
    theme.palette.grey[theme.palette.mode === "dark" ? "600" : "300"]
  } 10px, ${
    theme.palette.grey[theme.palette.mode === "dark" ? "700" : "200"]
  } 10px, ${
    theme.palette.grey[theme.palette.mode === "dark" ? "700" : "200"]
  } 20px)`;

const UpgradeAdornment = ({className, fontSize, item, link}) => {
  const itemText = item || "This item";
  return (
    <InputAdornment className={className} position="end">
      <StyledTooltip
        interactive
        title={
          <Typography variant="caption">
            {itemText} {pluralize.isPlural(itemText) ? "are " : "is "}not
            included in your current plan.{" "}
            <Link component={NavLink} to={link || "/plans"} underline="hover">
              More about pricing and plans.
            </Link>
          </Typography>
        }
      >
        <LockIcon color="primary" fontSize={fontSize} />
      </StyledTooltip>
    </InputAdornment>
  );
};

UpgradeAdornment.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.string,
  item: PropTypes.string,
  link: PropTypes.string,
};

export default UpgradeAdornment;

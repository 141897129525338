import React from "react";
import {Box, Grid} from "@mui/material";
import {StyledSkeleton} from "../../components/skeletons/StyledSkeleton";
import {map} from "underscore";

export const SkeletonIntegrations = () => {
  return (
    <Box
      py={2}
      px={1}
      m={2}
      mt={1.5}
      mb={0}
      id="skeleton-integrations"
      data-testid="skeleton-integrations"
    >
      <StyledSkeleton sx={{height: 30, width: 110}} />
      <Box mt={2}>
        <StyledSkeleton sx={{height: 20, width: 550}} />
      </Box>
      <Box mt={1}>
        <StyledSkeleton sx={{height: 30, width: 235}} />
      </Box>
      <Box mt={2}>
        {map(Array(7), (each, index) => (
          <Box key={index} mt={0.5} mb={2}>
            <StyledSkeleton sx={{height: 25, width: 120}} />
            <Grid container spacing={2}>
              <Grid item mt={1.5} mb={0.25} display="flex">
                <StyledSkeleton
                  sx={{height: 100, width: 200, marginRight: 2}}
                />
                <StyledSkeleton sx={{height: 100, width: 200}} />
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

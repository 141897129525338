export const integrationData = [
  {
    name: "google",
    title: "Google Workspaces",
    category: "Authentication",
    logo: require("~/public/integrations/google.png"),
    logoDetail: require("~/public/integrations/google.png"),
    configuration: {},
  },
  {
    name: "jira",
    title: "Jira",
    category: "Ticketing",
    logo: require("~/public/integrations/jira.svg"),
    logoDark: require("~/public/integrations/jira-reverse.png"),
    logoDetail: require("~/public/integrations/jira-detail.svg"),
    configuration: {},
  },
  {
    name: "msentra",
    title: "Microsoft Entra ID",
    category: "Authentication",
    logo: require("~/public/integrations/microsoft-entra.png"),
    logoDark: require("~/public/integrations/microsoft-entra-reverse.png"),
    logoDetail: require("~/public/integrations/microsoft-detail.png"),
    configuration: {},
  },
  {
    name: "msteams",
    title: "Microsoft Teams",
    category: "Notification",
    logo: require("~/public/integrations/microsoft-teams.png"),
    logoDetail: require("~/public/integrations/microsoft-detail.png"),
    configuration: {},
  },
  {
    name: "okta",
    title: "Okta",
    category: "Authentication",
    logo: require("~/public/integrations/okta.png"),
    logoDark: require("~/public/integrations/okta-reverse.png"),
    logoDetail: require("~/public/integrations/okta-detail.png"),
    configuration: {},
  },
  {
    name: "onelogin",
    title: "OneLogin",
    category: "Authentication",
    logo: require("~/public/integrations/onelogin.png"),
    logoDark: require("~/public/integrations/onelogin-reverse.png"),
    logoDetail: require("~/public/integrations/onelogin-detail.png"),
    configuration: {},
  },
  {
    name: "opsgenie",
    title: "Opsgenie",
    category: "Notification",
    logo: require("~/public/integrations/ops-genie.svg"),
    logoDetail: require("~/public/integrations/ops-genie-detail.svg"),
    configuration: {},
  },
  {
    name: "pagerduty",
    title: "PagerDuty",
    category: "Notification",
    logo: require("~/public/integrations/pager-duty.svg"),
    logoDetail: require("~/public/integrations/pager-duty-detail.png"),
    configuration: {},
  },
  {
    name: "ping",
    title: "Ping",
    category: "Authentication",
    logo: require("~/public/integrations/ping.svg"),
    logoDark: require("~/public/integrations/ping-reverse.svg"),
    logoDetail: require("~/public/integrations/ping-detail.png"),
    configuration: {},
  },
  {
    name: "xsoar",
    title: "Palo Alto Cortex XSOAR",
    category: "Security Orchestration",
    logo: require("~/public/integrations/palo-alto-cortex-xsoar.png"),
    logoDark: require("~/public/integrations/palo-alto-cortex-xsoar-reverse.png"),
    logoDetail: require("~/public/integrations/palo-alto-cortex-xsoar-detail.png"),
    configuration: {},
  },
  {
    name: "revelstoke",
    title: "Revelstoke",
    category: "Security Orchestration",
    logo: require("~/public/integrations/revelstoke.png"),
    logoDark: require("~/public/integrations/revelstoke-reverse.png"),
    logoDetail: require("~/public/integrations/revelstoke-detail.png"),
    configuration: {},
  },
  {
    name: "salesforce",
    title: "Salesforce",
    category: "CRM",
    logo: require("~/public/integrations/salesforce.png"),
    logoDetail: require("~/public/integrations/salesforce.png"),
    configuration: {},
  },
  {
    name: "slack",
    title: "Slack",
    category: "Notification",
    logo: require("~/public/integrations/slack.png"),
    logoDark: require("~/public/integrations/slack-reverse.png"),
    logoDetail: require("~/public/integrations/slack-detail.png"),
    configuration: {},
  },
  {
    name: "vanta",
    title: "Vanta",
    category: "Compliance",
    logo: require("~/public/integrations/vanta.png"),
    logoDark: require("~/public/integrations/vanta-reverse.png"),
    logoDetail: null, // VantaDetailIcon inline
    configuration: {},
  },
  {
    name: "zapier",
    title: "Zapier",
    category: "Utility",
    logo: require("~/public/integrations/zapier.png"),
    logoDark: require("~/public/integrations/zapier-reverse.png"),
    logoDetail: require("~/public/integrations/zapier-detail.svg"),
    configuration: {},
  },
  {
    name: "zoom",
    title: "Zoom",
    category: "Communication",
    logo: require("~/public/integrations/zoom.png"),
    logoDetail: require("~/public/integrations/zoom-detail.png"),
    configuration: {},
  },
];

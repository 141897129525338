import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useLazyQuery, useMutation} from "@apollo/client";
import jwtDecode from "jwt-decode";
import {GET_INCIDENTS_ON_LOGIN} from "../../../graphql/incidentQueries";
import {
  GET_USER,
  UPDATE_LAST_LOGIN,
  SYNC_USER_ORGANIZATION_MFA,
} from "../../../graphql/userQueries";
import {initializeMetricsForUser} from "../../../resources/metrics";
import {getDisplayName} from "../../../resources/users";
import {useAuth} from "../../authentication/Context/AuthProvider";
import MFASetupForm from "../../authentication/MFA/MFASetupForm";
import Loader from "../../Loader";
import {find, findWhere, includes, isArray} from "underscore";

const UserContext = createContext(undefined);

const ADMIN_ROLE_ID = 1;

const UserProvider = ({children}) => {
  const {authSession, logout, orgName, setErrorMessage} = useAuth();

  const [getIncidents] = useLazyQuery(GET_INCIDENTS_ON_LOGIN);
  const [getUser, {data, stopPolling}] = useLazyQuery(GET_USER, {
    pollInterval: 5000,
  });
  const [updateLastLogin] = useMutation(UPDATE_LAST_LOGIN);
  const [syncUserMfa, {data: MFAData, error: MFAError}] = useMutation(
    SYNC_USER_ORGANIZATION_MFA,
  );
  const [user, setUser] = useState();
  const [phoneRequired, setPhoneRequired] = useState(false);

  useEffect(async () => {
    const payload = jwtDecode(authSession.IdToken);
    await syncUserMfa();
    getIncidents();
    getUser({variables: {email: payload.email}});
  }, []);

  useEffect(() => {
    const payload = jwtDecode(authSession.IdToken);
    const provider = payload["cognito:username"].split("_")[0];
    if (
      ["googlesaml", "okta", "onelogin"].includes(provider) &&
      isArray(payload?.identities) &&
      (findWhere(payload.identities, {providerName: "GoogleSAML"}) ||
        findWhere(payload.identities, {providerName: "Okta"}) ||
        findWhere(payload.identities, {providerName: "OneLogin"}))
    ) {
      return;
    }
    if (MFAData) {
      if (MFAData.syncMfaRequired === "phone_required") {
        setPhoneRequired(true);
      }
      if (MFAData.syncMfaRequired === "sms_mfa_enabled") {
        setErrorMessage(
          "SMS MFA is now enabled.  Please try logging in again, " +
            "and when prompted, enter the code sent to the phone number given in your profile.",
        );
        logout();
      }
      if (MFAData.syncMfaRequired === "invite_needed") {
        setErrorMessage("Please request an invite from this organization.");
        logout();
      }
      if (MFAData.syncMfaRequired === "user_configured") {
        setErrorMessage(
          "One time configuration complete. Please log in again.",
        );
        logout();
      }
    }
  }, [MFAData, MFAError]);

  useEffect(() => {
    if (!data || !data.user) return;
    stopPolling();

    if (!user && !phoneRequired) {
      updateLastLogin({variables: {user: {id: data.user.id}}});
    }

    const accessPayload = jwtDecode(authSession.AccessToken);
    const isCognito =
      "Cognito" in data.user?.providers &&
      (data.user?.providers.Cognito.uuid === accessPayload.sub ||
        includes(data.user?.providers.Cognito?.uuids, accessPayload.sub));

    setUser({
      ...data.user,
      hasAdminRole: !!find(
        data.user?.roles,
        (each) => each?.role?.id === ADMIN_ROLE_ID,
      ),
      profilePicUrl: data.user?.profileDocumentPreview ?? null,
      displayName: getDisplayName(data.user),
      isCognito,
    });
    initializeMetricsForUser(orgName, data.user?.email);
  }, [data]);

  if (!MFAData) return <Loader fullViewingHeight />;

  return (
    <UserContext.Provider value={{user, setUser}}>
      {phoneRequired ?
        <MFASetupForm /> :
        children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useUser = () => {
  return useContext(UserContext);
};

export {UserProvider, useUser, UserContext};

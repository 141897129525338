import * as yup from "yup";
import YupPassword from "yup-password";

// eslint-disable-next-line new-cap
YupPassword(yup);

export const MIN_PASSWORD_LENGTH = 10;
const PASSWORD_SCHEMA = yup
  .string()
  .min(
    MIN_PASSWORD_LENGTH,
    `Password must contain ${MIN_PASSWORD_LENGTH} or more characters.`,
  )
  .minLowercase(1, "Password must contain at least 1 lowercase character.")
  .minUppercase(1, "Password must contain at least 1 uppercase character.")
  .minNumbers(1, "Password must contain at least 1 number.")
  .minSymbols(1, "Password must contain at least 1 special character.");

export const loginSchema = yup
  .object()
  .shape({
    username: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export const mfaSchema = yup
  .object()
  .shape({
    mfaCode: yup
      .string()
      .matches(/^[0-9]+$/)
      .min(6)
      .max(6),
  })
  .required();

export const newPasswordSchema = yup
  .object()
  .shape({
    newPassword: PASSWORD_SCHEMA,
    confirmNewPassword: yup
      .string()
      .required("Confirming password is required."),
  })
  .required();

export const forgotPasswordSchema = yup
  .object()
  .shape({
    forgotPasswordCode: yup
      .string()
      .matches(/^[0-9]+$/)
      .min(6)
      .max(6),
  })
  .required();

export const changePasswordSchema = yup
  .object()
  .shape({
    currentPassword: yup
      .string()
      .required("Current password is a required field."),
    newPassword: PASSWORD_SCHEMA,
    confirmNewPassword: yup
      .string()
      .required("Confirming password is required."),
  })
  .required();

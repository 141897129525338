import React from "react";
import {Button, Container, Grid, Paper, Typography} from "@mui/material";
import axios from "axios";
import {tagline} from "../../resources/environment";
import {useAuthStyles} from "./AuthStyles";
import {SubmitButton} from "../buttons";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {newPasswordSchema} from "./schemas";
import NewPasswordFields from "./NewPasswordFields";
import {useAuth} from "./Context/AuthProvider";
import {isEmpty} from "underscore";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const NewUserPasswordForm = () => {
  const {classes} = useAuthStyles();
  const formMethods = useForm({resolver: yupResolver(newPasswordSchema)});
  const {
    authSession,
    setAuthSession,
    errorMessage,
    setErrorMessage,
    isAuthLoading,
    setIsAuthLoading,
    orgName,
  } = useAuth();

  const confirmNewUserPassword = async (data) => {
    if (data.newPassword !== data.confirmNewPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    if (!isEmpty(errorMessage)) setErrorMessage("");
    setIsAuthLoading(true);
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/password/confirm`, {
        orgName,
        ChallengeName: "NEW_PASSWORD_REQUIRED",
        ChallengeResponses: {
          NEW_PASSWORD: data.newPassword,
          USERNAME: authSession.username,
        },
        Session: authSession.Session,
      });
      setAuthSession({
        ...response.data,
        rememberMe: authSession.rememberMe,
        username: authSession.username,
      });
    } catch (error) {
      setErrorMessage(error.response?.data?.message ?? error?.message ?? error);
    } finally {
      setIsAuthLoading(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Container className={classes.loginContainer}>
        <center>
          <Paper elevation={10} square className={classes.newPassword}>
            <Grid container className={classes.centered}>
              <Grid item xs={12}>
                <Button
                  disabled
                  className={classes.logo}
                  aria-label="BreachRx"
                  alt="BreachRx Logo"
                />
                <Typography variant="h5" className={classes.tagline}>
                  {tagline}
                </Typography>
                <Typography variant="h5" className={classes.subheading}>
                  Welcome
                </Typography>
              </Grid>
            </Grid>
            <form
              onSubmit={formMethods.handleSubmit(confirmNewUserPassword)}
              className={classes.form}
            >
              <NewPasswordFields />
              <SubmitButton isLoading={isAuthLoading}>Submit</SubmitButton>
            </form>
            <Typography
              variant="caption"
              display="block"
              color="error"
              gutterBottom
            >
              {!isEmpty(errorMessage) ? errorMessage : ""}
            </Typography>
          </Paper>
        </center>
      </Container>
    </FormProvider>
  );
};

export default NewUserPasswordForm;

import {makeStyles} from "tss-react/mui";

export const useWizardStyles = makeStyles()((theme) => ({
  tourProviderPopover: {
    [theme.breakpoints.between("sm", "xl")]: {
      width: `${(3 / 12) * 100}% !important`,
    },
    backgroundColor: `${theme.palette.layout.wizard.background} !important`,
  },
  wrapper: {
    display: "flex",
    flexGrow: 1,
    height: "calc(100vh - 100px)",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    height: "80vh",
    boxShadow: theme.palette.layout.wizard.boxShadow,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    padding: theme.spacing(1),
    textAlign: "center",
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      color: theme.palette.primary.ultralight,
    },
    "& .MuiStepLabel-label.Mui-completed": {
      color: "inherit",
    },
    "& .MuiStepLabel-label.Mui-active": {
      color: theme.palette.primary.main,
    },
  },
  stepper: {
    padding: 24,
    "& .MuiStepConnector-alternativeLabel": {
      top: theme.spacing(2.75),
    },
    "& .MuiStepConnector-root.Mui-active": {
      "& .MuiStepConnector-line": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .MuiStepConnector-root.Mui-completed": {
      "& .MuiStepConnector-line": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .MuiStepConnector-line": {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.primary.ultralight,
      borderRadius: 1,
    },
  },
  icon: {
    zIndex: 1,
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: `0 0 0 2px ${theme.palette.primary.ultralight}`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.ultralight,
  },
  iconActive: {
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  iconCompleted: {
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  formPane: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
  },
  info: {
    marginTop: -theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  wizPageWrapper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  wizPageInputWrapper: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    flexGrow: 1,
    overflowY: "auto",
    scrollbarWidth: "thin",
  },
  controlsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: theme.spacing(3),
    gap: theme.spacing(1),
  },
  loaderWrapper: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 150px)",
  },
  pbLoaderWrapper: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 150px)",
  },
  pbPreview: {
    marginTop: theme.spacing(4),
  },
  pbPrompt: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    backgroundColor: theme.palette.background.info,
    "& p": {
      marginBottom: theme.spacing(2),
      color: theme.palette.text.info,
    },
  },
  summarySection: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

import amplitude from "amplitude-js";
import LogRocket from "logrocket";
import shajs from "sha.js";
import {isEmpty} from "underscore";

const APP_VERSION = process.env.PACKAGE_VERSION;
const ENVIRONMENT_NAME = process.env.ENVIRONMENT_NAME;
const isProduction = ENVIRONMENT_NAME === "production";
const shouldUseMetrics = !["remotedev", "localdev", undefined].includes(
  ENVIRONMENT_NAME,
);

export const initializeMetrics = () => {
  if (!shouldUseMetrics) {
    console.info(
      "Local development or unknown environment - disabling anonymized metrics",
    );
    return;
  }

  const amplitudeKey =
    isProduction ?
      "95f1ab38af695c6e730f3bfe623061e1" :
      "3f589041d70066936730dcd7dee086e8";
  try {
    const appVersion = APP_VERSION ?? "unknown";
    amplitude.getInstance().init(
      amplitudeKey,
      null,
      {
        includeUtm: true,
        sameSiteCookie: "Strict",
        secureCookie: true,
        saveEvents: true,
        savedMaxCount: 10,
        unsentKey: "anonymized_metrics_unsent",
        unsentIdentifyKey: "anonymized_metrics_unsent_idKey",
        onExitPage: () => amplitude.getInstance().logEvent("app - exit"),
      },
      () => {
        amplitude.getInstance().setVersionName(appVersion);
      },
    );
  } catch (err) {
    console.error("Error initializing anonymized metrics");
  }
};

export const initializeMetricsForUser = (org, user) => {
  if (!shouldUseMetrics || isEmpty(user)) return;
  const encodedUser = shajs("sha256").update(user).digest("hex");
  const encodedOrg = shajs("sha256").update(org).digest("hex");
  amplitude.getInstance().setUserId(encodedUser);
  amplitude.getInstance().setGroup("orgId", encodedOrg);
  LogRocket.getSessionURL((sessionURL) => {
    emitMetric("app - enter", {LogRocket: sessionURL});
  });
};

const getMetricLocation = (location) =>
  location?.pathname?.split("/")?.[1]?.slice(0, -1);
const getTabNameForMetric = (tab) =>
  tab ? (tab?.metric ?? tab?.label)?.toLowerCase() : undefined;

export const emitMetric = (eventName, properties) => {
  if (!shouldUseMetrics || isEmpty(eventName)) return;
  const {location, tab, ...otherProperties} = properties;
  const eventLocation = getMetricLocation(location);
  otherProperties.tab = getTabNameForMetric(tab);
  if (!eventLocation) {
    amplitude.getInstance().logEvent(eventName, otherProperties);
  } else {
    amplitude
      .getInstance()
      .logEvent(`${eventName} ${eventLocation}`, otherProperties);
  }
};

export const emitStartStopMetric = (eventName, properties) => {
  if (!shouldUseMetrics || isEmpty(eventName)) return;
  const {location, ...otherProperties} = properties;
  const eventLocation = getMetricLocation(location);
  if (isEmpty(eventLocation)) return;
  amplitude
    .getInstance()
    .logEvent(`${eventName} ${eventLocation} - start`, otherProperties);
  return () =>
    amplitude
      .getInstance()
      .logEvent(`${eventName} ${eventLocation} - complete`, otherProperties);
};

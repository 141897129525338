import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Toolbar} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ALERT, sidebarWidth, sidebarClass} from "../resources/environment";
import {useSidebar} from "./sidebar/Context/SidebarProvider";

const useMainStyles = makeStyles()((theme) => ({
  content: {
    paddingLeft: sidebarWidth.open,
    [`&.${sidebarClass}`]: {
      paddingLeft: sidebarWidth.collapsed,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(1),
      },
    },
    flexGrow: 1,
    zIndex: theme.zIndex.drawer,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
    },
    "@media print": {
      paddingLeft: 0,
    },
  },
}));

const Main = ({children}) => {
  const {isSidebarHidden} = useSidebar();
  const {classes} = useMainStyles();
  const sidebarHiddenClass = isSidebarHidden ? `${sidebarClass}` : "";

  useEffect(() => {
    ALERT.success("Welcome!");
  }, []);

  return (
    <main className={`${classes.content} ${sidebarHiddenClass}`} id="main">
      <Toolbar />
      {children}
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.object,
};

export default Main;

import {gql} from "@apollo/client";

export const UPDATE_JIRA_INTEGRATION = gql`
  mutation UpdateJiraIntegration(
    $hasLinkedJiraAddon: Boolean
    $label: String
    $isEnabled: Boolean
  ) {
    updateJiraIntegration(
      data: {
        isEnabled: $isEnabled
        hasLinkedJiraAddon: $hasLinkedJiraAddon
        label: $label
      }
    ) {
      name
      configuration
    }
  }
`;

export const DELETE_JIRA_INTEGRATION = gql`
  mutation DeleteJiraIntegration {
    deleteJiraIntegration {
      name
      configuration
    }
  }
`;

export const SET_JIRA_ACCESS_TOKEN = gql`
  mutation SetJiraAccessTokens($code: String!) {
    setJiraAccessTokens(code: $code) {
      name
      configuration
    }
  }
`;

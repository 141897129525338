import {makeStyles} from "tss-react/mui";
import {needUpgradeBackground} from "../UpgradeAdornment";

export const useCustomSelectStyles = makeStyles()((theme, {needUpgrade}) => ({
  primary: {
    background: needUpgrade ? needUpgradeBackground(theme) : undefined,
  },
  spaceTop: {
    marginTop: theme.spacing(2),
  },
}));

export const useCustomTextFieldStyles = makeStyles()(
  (theme, {needUpgrade}) => ({
    primary: {
      background: needUpgrade ? needUpgradeBackground(theme) : undefined,
    },
  }),
);

export const useFormBuilderStyles = makeStyles()((theme) => ({
  buttonGroup: {
    display: "flex",
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  quickButton: {
    marginLeft: theme.spacing(1),
  },
  addTaskDialog: {
    minWidth: theme.spacing(100),
    minHeight: theme.spacing(50),
  },
  closeDialog: {
    position: "absolute",
    right: theme.spacing(2),
    "&:hover": {
      backgroundColor: "unset",
    },
  },
}));

export const useCustomAutocompleteStyles = makeStyles()((theme, {margin}) => ({
  span: {
    marginBottom: margin?.bottom ? theme.spacing(margin.bottom) : undefined,
    marginLeft: margin?.left ? theme.spacing(margin.left) : undefined,
    marginRight: margin?.right ? theme.spacing(margin.right) : undefined,
    marginTop: margin?.top ? theme.spacing(margin.top) : undefined,
    display: "block",
  },
  inputRoot: {
    "& .MuiAutocomplete-input": {
      minWidth: theme.spacing(7.4),
    },
    "&[class*='MuiFilledInput-root']": {
      padding: ".5rem",
      borderRadius: 0,
    },
  },
}));

export const useCustomDateTimePickerStyles = makeStyles()(
  (theme, {error} = {}) => ({
    inputBox: {
      fieldset: {
        borderColor: error ? theme.palette.error.main : undefined,
      },
    },
  }),
);

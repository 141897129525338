import {gql} from "@apollo/client";

export const CORE_TAG_FIELDS = gql`
  fragment CoreTagFields on Tag {
    id
    key
    value
    isDeleted
  }
`;

export const EXTENDED_TAG_FIELDS = gql`
  ${CORE_TAG_FIELDS}
  fragment ExtendedTagFields on Tag {
    ...CoreTagFields
    authKeys {
      id
      isDeleted
      authKeyId
      authKey {
        id
        isDeleted
      }
    }
    incidents {
      id
      isDeleted
      incidentId
      incident {
        id
        isDeleted
      }
    }
    notificationTriggers {
      id
      isDeleted
      notificationId
      notification {
        id
        isDeleted
      }
    }
    playbook {
      id
      isDeleted
      playbookId
      playbook {
        id
        isDeleted
      }
    }
    obligations {
      id
      isDeleted
      obligationId
      obligation {
        id
        isDeleted
      }
    }
    roles {
      id
      isDeleted
      roleId
      role {
        id
        isDeleted
      }
    }
  }
`;

export const CREATE_TAG = gql`
  ${CORE_TAG_FIELDS}
  mutation AddTag($tag: String!) {
    createOneTag(data: {key: "tag", value: $tag}) {
      ...CoreTagFields
    }
  }
`;

export const GET_TAGS = gql`
  ${CORE_TAG_FIELDS}
  query GetTags {
    tags(orderBy: {value: asc}) {
      ...CoreTagFields
    }
  }
`;

export const GET_TAGS_FOR_SCHEMA = gql`
  ${EXTENDED_TAG_FIELDS}
  query GetTags {
    tags(orderBy: {value: asc}) {
      ...ExtendedTagFields
    }
  }
`;

export const UPDATE_TAG = gql`
  ${CORE_TAG_FIELDS}
  mutation updateTag($id: Int!, $tag: String!) {
    updateOneTag(
      where: {id: $id}
      data: {key: {set: "tag"}, value: {set: $tag}}
    ) {
      ...CoreTagFields
    }
  }
`;

export const DELETE_TAG = gql`
  ${CORE_TAG_FIELDS}
  mutation DeleteTag($id: Int!) {
    deleteOneTag(where: {id: $id}) {
      ...CoreTagFields
    }
  }
`;

import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDownOutlined";
import {useTopbarStyles} from "./topbarStyles";
import StyledTooltip from "../tooltips/StyledTooltip";

const TopbarSplitbutton = ({options}) => {
  const {classes} = useTopbarStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <ButtonGroup
        className={classes.topbarIncidentButtonGroup}
        variant="outlined"
        ref={anchorRef}
        aria-label="topbar button group"
      >
        <StyledTooltip
          title={`${options[selectedIndex].title} (${options[selectedIndex].key})`}
        >
          <Button
            className={classes.topbarIncidentButton}
            to={options[selectedIndex].route}
            component={NavLink}
            aria-label={options[selectedIndex].title.toLowerCase()}
            variant="outlined"
            color="grey"
            id="new-incident"
            onClick={() => setSelectedIndex(0)}
          >
            <Typography variant="caption" className={classes.buttonText}>
              {options[selectedIndex].title}
            </Typography>
          </Button>
          <Button
            size="small"
            className={classes.topbarSpace}
            aria-controls={open ? "topbar-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select create option"
            aria-haspopup="menu"
            color="grey"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </StyledTooltip>
      </ButtonGroup>
      <Popper
        sx={{zIndex: 1}}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      aria-label={option.title.toLowerCase()}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

TopbarSplitbutton.propTypes = {
  options: PropTypes.array,
};

export default TopbarSplitbutton;

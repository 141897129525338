import React from "react";
import {NavLink} from "react-router-dom";
import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import RemoveFromQueueOutlinedIcon from "@mui/icons-material/RemoveFromQueueOutlined";
import Powerbar, {usePowerbar} from "../powerbar/Powerbar";
import {useSidebar} from "../sidebar/Context/SidebarProvider";
import {useOrgSettings} from "../../context/OrgSettingsProvider";
import StyledTooltip from "../tooltips/StyledTooltip";
import TopbarSplitbutton from "./TopbarSplitButton";
import {TopbarProfile} from "./TopbarProfile";
import {useTopbarStyles} from "./topbarStyles";
import GettingStarted from "./GettingStarted";
import {useFeatureFlags} from "../../context/FeatureFlagProvider";
import {powerbarItems} from "../powerbar/PowerbarItems";
import {findWhere} from "underscore";

export const Topbar = () => {
  const {classes} = useTopbarStyles();
  const {toggleSidebar} = useSidebar();
  const {responseWorkflowName} = useOrgSettings();
  const powerbar = usePowerbar();
  const {showGettingStarted} = useFeatureFlags();

  const options = [
    {
      title: `Create ${responseWorkflowName.upper}`,
      route: `/${responseWorkflowName.lower}s/wizard`,
      key: findWhere(powerbarItems, {
        action: `Create ${responseWorkflowName.upper}`,
      })?.key,
    },
    {
      title: "Create Exercise",
      route: `/${responseWorkflowName.lower}s/exercise`,
      key: findWhere(powerbarItems, {action: "Create Exercise"})?.key,
    },
  ];

  return (
    <div className={classes.grow} id="topbar">
      <Powerbar state={powerbar} />
      <AppBar position="fixed" className={classes.appBar} enableColorOnDark>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.toolbarGroup}>
            <StyledTooltip title="Toggle Sidebar">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="toggle sidebar"
                id="toggle-sidebar"
                onClick={() => toggleSidebar()}
                size="large"
              >
                <MenuIcon className={classes.topbarButton} />
              </IconButton>
            </StyledTooltip>
            <NavLink
              className={classes.topbarLogo}
              to="/dashboard"
              aria-label="BreachRx logo"
              id="logo"
            />
          </Box>
          <Box className={classes.toolbarGroup}>
            {showGettingStarted && (
              <Box pr={3}>
                <GettingStarted />
              </Box>
            )}
            <div className={classes.topbarButtonMenu}>
              <TopbarSplitbutton options={options} />
              <StyledTooltip title="Open Powerbar (ctrl+alt+p)">
                <IconButton
                  className={`${classes.topbarIconButton} ${classes.topbarHideIfMobile}`}
                  aria-label="show powerbar"
                  onClick={() => powerbar.setOpen()}
                  id="powerbar"
                  size="large"
                >
                  <RemoveFromQueueOutlinedIcon
                    className={classes.topbarButton}
                  />
                </IconButton>
              </StyledTooltip>
            </div>
            <div className={classes.rightMenu}>
              <div className={classes.grow} />
              <TopbarProfile />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

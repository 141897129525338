import React from "react";
import PropTypes from "prop-types";
import {filter, groupBy, keys, map, sortBy} from "underscore";
import {Box, Grid, Typography} from "@mui/material";
import {Error, PageTitle} from "../../components";
import {SkeletonIntegrations} from "./SkeletonIntegrations";
import TextSearch from "../../components/search/TextSearch";
import {
  IntegrationsProvider,
  useIntegrations,
} from "./Context/IntegrationContext";
import {IntegrationCard} from "./Controls/IntegrationCard";
import {escapeRegExp} from "../../resources/utils/stringUtils";
import {useOauthHook} from "./Context/hooks/useOauthHook";
import {useIntegrationStyles} from "./IntegrationStyles";

const Integrations = () => (
  <IntegrationsProvider>
    <IntegrationsContent />
  </IntegrationsProvider>
);

const IntegrationsContent = () => {
  useOauthHook();
  const {loading, error, integrations, searchText, setSearchText} =
    useIntegrations();
  const {classes} = useIntegrationStyles();

  if (loading) return <SkeletonIntegrations />;
  if (error) return <Error error={error} />;

  const filteredIntegrations =
    searchText !== "" ?
      filter(integrations, (integration) => {
        const search = new RegExp(escapeRegExp(searchText), "i");
        const {name, title, category} = integration;
        return search.test(name) || search.test(title) || search.test(category);
      }) :
      integrations;
  const integrationCategories = groupBy(filteredIntegrations, "category");
  const integrationCategoryKeys = sortBy(keys(integrationCategories));

  const IntegrationCardGroup = ({group}) => (
    <Grid container spacing={2}>
      {map(integrationCategories?.[group], (integration, cardIndex) => (
        <Grid item key={`integration-${cardIndex}`}>
          <IntegrationCard integration={integration} />
        </Grid>
      ))}
    </Grid>
  );
  IntegrationCardGroup.propTypes = {group: PropTypes.string};

  return (
    <Box py={2} px={1} m={2} mt={1} mb={0}>
      <PageTitle id="integration-list-title">Integrations</PageTitle>
      <Box mt={2}>
        <Typography variant="subtitle1" className={classes.noPrint}>
          Click on an Integration below to learn more and update configuration
          settings
        </Typography>
        <TextSearch onChange={(text) => setSearchText(text)} />
      </Box>
      <Box mt={2}>
        {map(integrationCategoryKeys, (integrationGroupKey, index) => (
          <Box key={index}>
            <h3 style={{fontSize: "1rem"}}>{integrationGroupKey}</h3>
            <IntegrationCardGroup group={integrationGroupKey} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Integrations;

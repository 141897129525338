import React from "react";
import {Menu, useTheme} from "@mui/material";

const StyledTopbarMenu = (props) => {
  const theme = useTheme();
  return (
    <Menu
      elevation={1}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: {backgroundColor: theme.palette.background.default},
      }}
      {...props}
    />
  );
};

export default StyledTopbarMenu;

import React from "react";

export const overviewData = {
  dashboard: [
    {
      title: "Prepare",
      content: (
        <img src={require("~/public/overviews/breachrx/1-prepare.gif")} />
      ),
      description: `Know exactly what to do when an incident occurs by adding obligations, 
      adjusting regulations, and getting playbooks into place. Everything is customizable 
      in the BreachRx platform, so adjust our best practices to align with your internal 
      processes.`,
    },
    {
      title: "Respond",
      content: (
        <img src={require("~/public/overviews/breachrx/2-respond.gif")} />
      ),
      description: `Launch, execute, and collaborate on your response to events and incidents 
      seamlessly. Know what actions are on your to-do list, along with their deadlines. The 
      BreachRx platform is your command center and central system of record for your team.`,
    },
    {
      title: "Track & Manage",
      content: (
        <img src={require("~/public/overviews/breachrx/3-track-manage.gif")} />
      ),
      description: `Leverage reports and dashboards to track progress, gather metrics, identify 
      trends, and report out to everyone from leadership to regulators.`,
    },
  ],
  actions: [
    {
      title: "Prioritize",
      content: (
        <img src={require("~/public/overviews/actions/1-prioritize.gif")} />
      ),
      description: `Know exactly what actions to take during one or more incidents, which tasks 
      need to take priority, and when they're due.`,
    },
    {
      title: "Act",
      content: <img src={require("~/public/overviews/actions/2-act.gif")} />,
      description: `Easily keep a record of the activities you take in a central location keeping 
      an automated record of your work. Collaborate with others on your team on a task by task 
      basis.`,
    },
    {
      title: "Accomplish",
      content: (
        <img src={require("~/public/overviews/actions/3-accomplish.gif")} />
      ),
      description: `Reassign tasks to others on your team as needed. Close the task when complete 
      or delete it if it's no longer needed.`,
    },
  ],
  incidents: [
    {
      title: "Create",
      content: (
        <img src={require("~/public/overviews/incident/1-create.gif")} />
      ),
      description: `Get started by creating a new incident with either the incident wizard or the 
      incident add form. Include whatever incident attributes you know — you can always update or 
      expand them later. Or start with a playbook with templatized best practices and hit the 
      ground running when an incident occurs.`,
    },
    {
      title: "Act",
      content: <img src={require("~/public/overviews/incident/2-act.gif")} />,
      description: `Stay on top of incident progress, edit incident details, execute and assign 
        tasks, communicate in a privileged space, and add evidence, all while an audit log 
        automatically tracks your team's execution. Refer to related incidents from the past to 
        accelerate your response.`,
    },
    {
      title: "View",
      content: <img src={require("~/public/overviews/incident/3-view.gif")} />,
      description: `Review ongoing incidents, see their status, and approve any started by your 
        team. Reach into the archive as needed for training and get new team members up to 
        speed.`,
    },
  ],
  compliance: [
    {
      title: "Comply",
      content: (
        <img src={require("~/public/overviews/compliance/1-comply.gif")} />
      ),
      description: `Comply more rapidly with incident reporting and response requirements in 
      popular cybersecurity & privacy frameworks including SOC 2, ISO 27001, NIST CSF, CIS Controls,
      and more.`,
    },
    {
      title: "Plan",
      content: (
        <img src={require("~/public/overviews/compliance/2-plan.gif")} />
      ),
      description: `Demonstrate to customers, partners, and regulators that your organization 
      has a sophisticated cybersecurity & data protection program with a cutting-edge comprehensive 
      incident response plan and policy.`,
    },
  ],
  obligations: [
    {
      title: "Comply",
      content: (
        <img src={require("~/public/overviews/obligations/1-comply.gif")} />
      ),
      description: `Know what tasks you need to accomplish to maintain regulatory compliance with 
      BreachRx Privacy Intelligence. Regulations are kept up to date with the latest developments.`,
    },
    {
      title: "Act",
      content: (
        <img src={require("~/public/overviews/obligations/2-act.gif")} />
      ),
      description: `Hit the ground running with the actions you need to take for your contracts, 
      plans, policies, and other obligations that match the details of your incident.`,
    },
    {
      title: "Customize",
      content: (
        <img src={require("~/public/overviews/obligations/3-customize.gif")} />
      ),
      description: `Adapt existing best-practice obligations and their tasks to your organization 
      and team. Add new obligations to cover your program requirements.`,
    },
  ],
  tasks: [
    {
      title: "Reduce Risk",
      content: (
        <img src={require("~/public/overviews/tasks/1-reduce-risk.gif")} />
      ),
      description: `Know what tasks you need to accomplish to reduce regulatory risk with 
      BreachRx Privacy Intelligence. Tasks are kept up to date with their obligations.`,
    },
    {
      title: "Organize",
      content: <img src={require("~/public/overviews/tasks/2-organize.gif")} />,
      description: `BreachRx keeps tasks dynamically up to date with changes in best practices and 
      obligations. Easily stay on top of your task library: edit, duplicate, promote, and delete 
      tasks as needed to stay prepared.`,
    },
    {
      title: "Customize",
      content: (
        <img src={require("~/public/overviews/tasks/3-customize.gif")} />
      ),
      description: `Adapt existing best-practice tasks to your organization and team. Add new 
      obligations to cover your program requirements.`,
    },
  ],
  playbooks: [
    {
      title: "Prepare",
      content: (
        <img src={require("~/public/overviews/playbooks/1-prepare.gif")} />
      ),
      description: `Get playbooks into place for the types of events your organization faces on a 
      recurring basis. Playbooks automatically guide you with the exact actions to take for a 
      variety of different incidents.`,
    },
    {
      title: "Organize",
      content: (
        <img src={require("~/public/overviews/playbooks/2-organize.gif")} />
      ),
      description: ` Easily stay on top of your playbook library: draft, edit, disable, archive, 
      and delete playbooks as needed to stay prepared. BreachRx keeps playbooks dynamically up to 
      date with changes in best practices and obligations.`,
    },
    {
      title: "Accelerate",
      content: <img src="/overviews/playbooks/3-accelerate.gif" />,
      description: `Kick off an incident using a playbook so you know you're getting the best 
      guidance in the market and can return to business as usual sooner.`,
    },
  ],
  reports: [
    {
      title: "Track",
      content: <img src={require("~/public/overviews/reports/1-track.gif")} />,
      description: `Track the execution of each incident response with automatically-generated, 
      detailed and factual reports that can be given to peers, leadership, board membership, 
      regulators, and other concerned parties while protecting privilege.`,
    },
    {
      title: "Measure",
      content: (
        <img src={require("~/public/overviews/reports/2-measure.gif")} />
      ),
      description: `Collect metrics on incident response program execution over any period of time, 
      identify trends in incident occurrence, and get a top-level view of all active incidents in 
      real time.`,
    },
    {
      title: "Assess",
      content: (
        <img src={require("~/public/overviews/reports/2_5-assess.gif")} />
      ),
      description: `Use the industry's first risk assessment for regulatory, contractual, and other
      requirements. Determine the potential impacts of various types of incidents with one or 
      more datasets, and identify potential changes to datasets that would reduce that impact.`,
    },
  ],
  dataOptions: [
    {
      title: "Create",
      content: <img src={require("~/public/overviews/data/1-create.gif")} />,
      description: `Add custom conditions that can be used to filter down to the exact actions 
      your team needs to take based on their use as incident attributes. Create new incident types, 
      severities, phases, and other underlying data to match your existing processes.`,
    },
    {
      title: "Customize",
      content: <img src={require("~/public/overviews/data/2-customize.gif")} />,
      description: `Update existing data elements and their descriptions, add options for 
      lists, and customize their appearance.`,
    },
    {
      title: "Reorder",
      content: <img src={require("~/public/overviews/data/3-reorder.gif")} />,
      description: `Change the order of phases and severities to align them with how your team 
      operates.`,
    },
  ],
  datasets: [
    {
      title: "Create",
      content: (
        <img src={require("~/public/overviews/datasets/1-create.gif")} />
      ),
      description: `Create a dataset and capture the attributes relevant to a database, data
      warehouse, or other data store(s). Use the dataset to save time when starting incidents
      or creating playbooks. Datasets allow you to easily assess the risk of impacts from 
      incidents in our risk assessment report.`,
    },
    {
      title: "View & Update",
      content: (
        <img src={require("~/public/overviews/datasets/2-view-update.gif")} />
      ),
      description: `Review and update each dataset, its specific attributes, and the geography 
      related to its data.`,
    },
    {
      title: "Assess Risk",
      content: (
        <img src={require("~/public/overviews/datasets/3-assess-risk.gif")} />
      ),
      description: ` Capture an individual assessment of the risks of impacts from the
      potential involvement of each dataset in incidents.`,
    },
  ],
};

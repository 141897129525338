import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useHotkeys} from "react-hotkeys-hook";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Dialog,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {useAuthz} from "../../context/AuthzProvider";
import {useFeatureFlags} from "../../context/FeatureFlagProvider";
import {useOrgSettings} from "../../context/OrgSettingsProvider";
import {usePowerbarStyles} from "./PowerbarStyles";
import {powerbarItems} from "./PowerbarItems";

const Powerbar = ({state}) => {
  const ability = useAuthz();
  const navigate = useNavigate();
  const {classes} = usePowerbarStyles();
  const featureFlags = useFeatureFlags();
  const {responseWorkflowName} = useOrgSettings();

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 2) {
      featureFlags.setFeatureFlags((prevFlags) => ({
        ...prevFlags,
        showDeveloper: !prevFlags.showDeveloper,
      }));
      setCount(0);
    }
  }, [count]);

  useHotkeys("ctrl+alt+p", () => state.setOpen());
  useHotkeys("ctrl+alt+shift+`", () => setCount((prevCount) => prevCount + 1));
  powerbarItems.forEach((each) => {
    each.path =
      each.path.includes("incidents") ?
        each.path.replace("incidents", responseWorkflowName.lower + "s") :
        each.path;
    each.action =
      each.action.includes("Incident") ?
        each.action.replace("Incident", responseWorkflowName.upper) :
        each.action;
    useHotkeys(each.key, () => handleChange(null, each));
  });

  const handleChange = (event, value) => {
    state.setClosed();
    navigate(value.path);
  };

  return (
    <Dialog
      open={state.open}
      onClose={() => state.setClosed()}
      className={classes.powerbar}
    >
      <Autocomplete
        open
        autoHighlight
        options={powerbarItems.filter((item) => {
          return ability.can("update", item.relatedModelName);
        })}
        getOptionLabel={(option) => option.action || ""}
        isOptionEqualToValue={(option, value) => {
          return option && value && option?.key === value?.key;
        }}
        onChange={handleChange}
        clearOnEscape
        size="small"
        style={{width: 300}}
        renderOption={(props, option) => (
          <li {...props}>
            <span style={{width: "100%"}}>
              <Typography component="span" variant="body2">
                {option.action}
              </Typography>
              <Typography variant="caption" style={{float: "right"}}>
                ({option.key})
              </Typography>
            </span>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            // TextField traps all keystrokes and therefore blocks Escape from closing the powerbar
            onKeyUp={(e) => e.key === "Escape" && state.setClosed()}
            label=""
            variant="outlined"
          />
        )}
        PaperComponent={(params) => <Paper square {...params} />}
        ListboxProps={{style: {lineHeight: 1, maxHeight: "unset"}}}
      ></Autocomplete>
    </Dialog>
  );
};

Powerbar.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    setClosed: PropTypes.func.isRequired,
  }),
};

export default Powerbar;

export const usePowerbar = () => {
  const [powerBar, setPowerBar] = useState(false);
  return {
    open: powerBar,
    setOpen: () => setPowerBar(true),
    setClosed: () => setPowerBar(false),
  };
};

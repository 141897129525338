import shajs from "sha.js";
import {
  each,
  isArray,
  isFinite,
  isNumber,
  isObject,
  keys,
  mapObject,
} from "underscore";

export const sanitizeLogRocket = (isProduction) => {
  const isDemo = () => {
    const host = /(?:\w+)/.exec(window?.location?.hostname);
    return host?.[0]?.toLocaleLowerCase() === "demo";
  };
  return !!isProduction && !isDemo();
};

const hashValue = (value) => {
  const hashedValue = shajs("sha256").update(value).digest("hex");
  return `Anonymized ${hashedValue}`;
};

const anonymousValue = (value) => {
  if (value === null) return "Anonymized null";
  if (isNumber(value)) {
    return isFinite(value) ?
      "Anonymized finite number" :
      "Anonymized non-finite number";
  }
  if (isArray(value)) return `Anonymized array of length ${value.length}`;
  if (isObject(value)) {
    return `Anonymized object with ${keys(value)?.length} keys`;
  }
  return `Anonymized ${typeof value}`;
};

const bodyElementsToAnonymize = [
  "AccessToken",
  "AuthenticationResult",
  "ChallengeParameters",
  "ChallengeResponses",
  "confirmPassword",
  "ConfirmationCode",
  "currentPassword",
  "customerName",
  "IdToken",
  "newPassword",
  "orgTitle",
  "password",
  "Password",
  "RefreshToken",
  "Session",
  "url",
];

const bodyElementsToHash = [
  "cognitoPoolId",
  "cognitoPoolClientId",
  "documentsBucket",
  "email",
  "orgName",
  "orgFullName",
  "username",
  "Username",
];

export const logRocketRequestSanitizer = (request) => {
  if (request.headers?.["Authorization"]) {
    request.headers["Authorization"] =
      `Anonymized ${typeof request.headers["Authorization"]}`;
  }
  if (request.headers?.["authorization"]) {
    request.headers["authorization"] =
      `Anonymized ${typeof request.headers["authorization"]}`;
  }
  if (request.body) {
    try {
      const body = JSON.parse(request.body);
      if (body?.variables) {
        const anonVars = mapObject(
          body.variables,
          (val, key) => (key = anonymousValue(val)),
        );
        body.variables = anonVars;
      }
      each(bodyElementsToAnonymize, (elementToSanitize) => {
        if (body?.[elementToSanitize]) {
          body[elementToSanitize] = anonymousValue(body[elementToSanitize]);
        }
      });
      each(bodyElementsToHash, (elementToHash) => {
        if (body?.[elementToHash]) {
          body[elementToHash] = hashValue(body[elementToHash]);
        }
      });
      request.body = JSON.stringify(body);
    } catch (error) {
      // intentionally ignore
    }
  }
  return request;
};

export const logRocketResponseSanitizer = (response) => {
  if (response.body) {
    try {
      const body = JSON.parse(response.body);
      if (body?.data) {
        const dataKeys = keys(body.data);
        each(dataKeys, (key) => {
          const value = body.data[key];
          body.data[key] =
            isArray(value) ? value.map((val) => anonymousValue(val)) :
            isObject(value) ?
              mapObject(
                body.data[key],
                (val, key) => (key = anonymousValue(val)),
              ) :
              anonymousValue(value);
        });
      }
      each(bodyElementsToAnonymize, (elementToSanitize) => {
        if (body?.[elementToSanitize]) {
          body[elementToSanitize] = anonymousValue(body[elementToSanitize]);
        }
      });
      each(bodyElementsToHash, (elementToHash) => {
        if (body?.[elementToHash]) {
          body[elementToHash] = hashValue(body[elementToHash]);
        }
      });
      response.body = JSON.stringify(body);
    } catch (error) {
      // intentionally ignore
    }
  }
  return response;
};

import React, {createContext, useState, useContext} from "react";
import PropTypes from "prop-types";
import {NetworkErrorPage} from "../NetworkErrorPage";

export const NetworkErrorContext = createContext();

export const NetworkErrorProvider = ({children}) => {
  const [networkError, setNetworkError] = useState();

  return (
    <NetworkErrorContext.Provider
      value={{
        networkError,
        setNetworkError,
      }}
    >
      {networkError ? NetworkErrorPage : children}
    </NetworkErrorContext.Provider>
  );
};

NetworkErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNetworkError = () => {
  return useContext(NetworkErrorContext);
};

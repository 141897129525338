import {gql} from "@apollo/client";
import {CORE_ROLE_FIELDS} from "./roleQueries";

const PRIMARY_USER_FIELDS = gql`
  fragment PrimaryUserFields on User {
    id
    lastLoginAt
    email
    fullName
    nickname
    phone
    isEnabled
    wantsIncidentIdsVisible
    wantsInfiniteScroll
    wantsSidebarClosed
    isDeleted
    isSMSEnabled
    preferredNewsView
  }
`;

const BASE_CORE_USER_FIELDS = gql`
  ${PRIMARY_USER_FIELDS}
  fragment BaseCoreUserFields on User {
    ...PrimaryUserFields
    providers
    metadata
    profileDocumentPreview
    profileDocument {
      id
    }
  }
`;

const CORE_USER_FIELDS = gql`
  ${BASE_CORE_USER_FIELDS}
  ${CORE_ROLE_FIELDS}
  fragment CoreUserFields on User {
    ...BaseCoreUserFields
    roles {
      id
      roleId
      isDeleted
      role {
        ...CoreRoleFields
        accessPolicies {
          id
          accessPolicy {
            id
            action
            subject
            conditions
            subject
            fields
            inverted
          }
        }
      }
    }
  }
`;

const ADD_USER = gql`
  ${CORE_USER_FIELDS}
  mutation AddUser(
    $email: String!
    $fullName: String!
    $phone: String
    $nickname: String
    $roles: [UserRoleCreateManyUserInput!]!
  ) {
    createOneUser(
      data: {
        email: $email
        fullName: $fullName
        phone: $phone
        nickname: $nickname
        roles: {createMany: {data: $roles}}
      }
    ) {
      ...CoreUserFields
    }
  }
`;

const SYNC_USER_ORGANIZATION_MFA = gql`
  mutation SyncMfaRequired {
    syncMfaRequired
  }
`;

const UPDATE_LAST_LOGIN = gql`
  ${PRIMARY_USER_FIELDS}
  mutation UpdateLastLogin($user: UserWhereUniqueInput!) {
    updateUserLastLogin(user: $user) {
      ...PrimaryUserFields
    }
  }
`;

const REINVITE_USER = gql`
  ${CORE_USER_FIELDS}
  mutation ReinviteUser($email: String!, $fullName: String!) {
    createOneUser(data: {email: $email, fullName: $fullName}) {
      ...CoreUserFields
    }
  }
`;

const LIST_USERS = gql`
  ${BASE_CORE_USER_FIELDS}
  ${CORE_ROLE_FIELDS}
  query GetUsers($isEnabled: Boolean) {
    users(where: {isEnabled: {equals: $isEnabled}}) {
      ...BaseCoreUserFields
      roles {
        id
        roleId
        isDeleted
        role {
          ...CoreRoleFields
        }
      }
    }
  }
`;

const UPDATE_USER_ENABLEMENT = gql`
  ${CORE_USER_FIELDS}
  mutation UpdateUserEnablement($email: String!, $isEnabled: Boolean!) {
    updateOneUser(
      where: {email: $email}
      data: {isEnabled: {set: $isEnabled}}
    ) {
      ...CoreUserFields
    }
  }
`;

const GET_USER = gql`
  ${CORE_USER_FIELDS}
  query GetUser($email: String!) {
    user(where: {email: $email}) {
      ...CoreUserFields
    }
  }
`;

const GET_USER_BY_ID = gql`
  ${CORE_USER_FIELDS}
  query GetUser($id: Int!) {
    user(where: {id: $id}) {
      ...CoreUserFields
    }
  }
`;

const UPDATE_USER_METADATA = gql`
  ${CORE_USER_FIELDS}
  mutation UpdateUserMetadata($id: Int!, $metadata: JSON!) {
    updateOneUser(where: {id: $id}, data: {metadata: $metadata}) {
      ...CoreUserFields
    }
  }
`;

const EDIT_USER = gql`
  ${CORE_USER_FIELDS}
  mutation EditUser(
    $email: String!
    $fullName: String!
    $nickname: String!
    $phone: String
    $isEnabled: Boolean!
  ) {
    updateOneUser(
      where: {email: $email}
      data: {
        fullName: {set: $fullName}
        nickname: {set: $nickname}
        isEnabled: {set: $isEnabled}
        phone: {set: $phone}
      }
    ) {
      ...CoreUserFields
    }
  }
`;

const UPDATE_USER = gql`
  ${CORE_USER_FIELDS}
  mutation UpdateUser(
    $email: String!
    $fullName: String!
    $nickname: String!
    $phone: String!
    $providers: JSON!
    $wantsIncidentIdsVisible: Boolean!
    $wantsInfiniteScroll: Boolean!
    $wantsSidebarClosed: Boolean!
    $isSMSEnabled: Boolean!
    $preferredNewsView: NewsView!
  ) {
    updateUser(
      where: {email: $email}
      data: {
        fullName: {set: $fullName}
        nickname: {set: $nickname}
        phone: {set: $phone}
        providers: $providers
        wantsIncidentIdsVisible: {set: $wantsIncidentIdsVisible}
        wantsInfiniteScroll: {set: $wantsInfiniteScroll}
        wantsSidebarClosed: {set: $wantsSidebarClosed}
        isSMSEnabled: {set: $isSMSEnabled}
        preferredNewsView: {set: $preferredNewsView}
      }
    ) {
      ...CoreUserFields
    }
  }
`;

const UPDATE_USER_PROFILE_PIC = gql`
  ${CORE_USER_FIELDS}
  mutation UpdateUser(
    $email: String!
    $profileDocumentId: Int!
    $profileDocumentPreview: String!
  ) {
    updateOneUser(
      where: {email: $email}
      data: {
        profileDocument: {connect: {id: $profileDocumentId}}
        profileDocumentPreview: {set: $profileDocumentPreview}
      }
    ) {
      ...CoreUserFields
    }
  }
`;

const GET_USER_PROFILE_PIC = gql`
  query GetUserProfilePic($documentId: Int!) {
    downloadOneDocument(where: {id: $documentId})
  }
`;

const DELETE_USER_PROFILE_PIC_LINK = gql`
  ${CORE_USER_FIELDS}
  mutation DeleteProfilePicture($email: String!) {
    updateOneUser(
      where: {email: $email}
      data: {
        profileDocument: {delete: true}
        profileDocumentPreview: {set: null}
      }
    ) {
      ...CoreUserFields
    }
  }
`;

export {
  ADD_USER,
  LIST_USERS,
  REINVITE_USER,
  UPDATE_USER_ENABLEMENT,
  GET_USER,
  GET_USER_BY_ID,
  UPDATE_USER_METADATA,
  EDIT_USER,
  UPDATE_USER,
  GET_USER_PROFILE_PIC,
  UPDATE_USER_PROFILE_PIC,
  DELETE_USER_PROFILE_PIC_LINK,
  PRIMARY_USER_FIELDS,
  CORE_USER_FIELDS,
  UPDATE_LAST_LOGIN,
  SYNC_USER_ORGANIZATION_MFA,
};

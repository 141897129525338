import React from "react";
import PropTypes from "prop-types";
import {Badge} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {isString} from "underscore";

export const useBadgeStyle = makeStyles()((theme, {padParent, padBottom}) => ({
  root: {
    paddingRight:
      padParent ?
        Number.isFinite(padParent) ?
          theme.spacing(padParent) :
          theme.spacing(1) :
        undefined,
    paddingBottom:
      Number.isFinite(padBottom) ? theme.spacing(padBottom) : undefined,
  },
  badge: {
    padding: "0 !important",
    fontSize: "0.65rem !important",
    height: `${theme.spacing(2)} !important`,
    verticalAlign: "top !important",
  },
}));

const StyledBadge = ({
  badgeContent,
  children,
  color,
  max,
  overlap,
  padParent,
  padBottom,
}) => {
  const {classes} = useBadgeStyle({padParent, padBottom});

  if (
    (Number.isFinite(badgeContent) && badgeContent > 0) ||
    isString(badgeContent)
  ) {
    return (
      <Badge
        badgeContent={badgeContent}
        color={color || "error"}
        max={max}
        overlap={overlap || "rectangular"}
        classes={classes}
        id="badge"
      >
        {children}
      </Badge>
    );
  }

  return children || null;
};

StyledBadge.propTypes = {
  badgeContent: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  children: PropTypes.any,
  color: PropTypes.string,
  max: PropTypes.number,
  overlap: PropTypes.string,
  padParent: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  padBottom: PropTypes.number,
};

export default StyledBadge;

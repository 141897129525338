import {makeStyles} from "tss-react/mui";

export const useAuthStyles = makeStyles()(
  (theme, {authProviderCount} = {}) => ({
    loginContainer: {
      backgroundColor: theme.palette.background.default,
      height: "calc(100vh - 16px)",
      width: "calc(100vw - 16px)",
      maxWidth: "unset !important",
      display: "table-cell",
      verticalAlign: "middle",
      [theme.breakpoints.down("sm")]: {
        verticalAlign: "unset",
        paddingTop: theme.spacing(3),
      },
    },
    login: {
      maxWidth: "450px",
      padding: "30px 40px",
    },
    MFA: {
      maxWidth: "450px",
      padding: "30px 40px",
    },
    newPassword: {
      maxWidth: "450px",
      padding: "30px 40px",
    },
    centered: {
      textAlign: "center",
    },
    logo: {
      height: 43,
      width: 192,
      transform: "scale(1.2)",
      margin: "auto 0",
      backgroundImage: `url(${theme.palette.logo})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
    tagline: {
      color: "gray",
      marginTop: theme.spacing(2),
    },
    subheading: {
      color: "gray",
      marginTop: theme.spacing(2),
    },
    form: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    forgotPasswordLink: {
      marginTop: theme.spacing(1),
      cursor: "pointer",
    },
    submit: {
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    adornmentIcon: {
      color: "gray",
    },
    error: {
      marginTop: theme.spacing(2),
    },
    closeIcon: {
      float: "right",
      padding: 0,
    },
    noAutofill: {
      "& div.MuiOutlinedInput-root": {
        "&.Mui-focused input": {
          WebkitBoxShadow: `${theme.palette.background.default} 0px 0px 0px 100px inset`,
          WebkitTextFillColor: theme.palette.text.primary,
        },
        "input:-internal-autofill-selected": {
          WebkitBoxShadow: `${theme.palette.background.default} 0px 0px 0px 100px inset`,
          WebkitTextFillColor: theme.palette.text.primary,
        },
      },
    },
    buttonRow: {
      display: "grid",
      gridTemplateColumns:
        authProviderCount <= 1 ? "1fr" :
        authProviderCount % 2 === 0 ? "1fr 1fr" :
        "1fr 1fr 1fr",
      gridGap: theme.spacing(0.5),
    },
    providerLogo: {
      maxWidth: theme.spacing(4),
      marginRight: theme.spacing(0.75),
    },
    providerButton: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  }),
);

export const usePasswordChangeStyles = makeStyles()((theme, {noMargin}) => ({
  passwordChangeSpace: {
    marginTop: theme.spacing(6),
    display: noMargin ? "none" : undefined,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      display: "block",
    },
  },
  bottomSpace: {
    marginTop: theme.spacing(2),
  },
}));

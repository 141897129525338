import {makeStyles} from "tss-react/mui";

export const useCancelStyles = makeStyles()((theme, {margin, disabled}) => ({
  cancelButton: {
    marginTop: margin?.top ? theme.spacing(margin.top) : undefined,
    marginBottom: margin?.bottom ? theme.spacing(margin.bottom) : undefined,
    marginLeft: margin?.left ? theme.spacing(margin.left) : undefined,
    marginRight: margin?.right ? theme.spacing(margin.right) : undefined,
    borderColor: disabled ? theme.palette.grey[300] : theme.palette.error.main,
    color: disabled ? theme.palette.grey[300] : theme.palette.error.main,
    "&:hover": {
      backgroundColor:
        disabled ? undefined : `${theme.palette.error.main} !important`,
      borderColor:
        disabled ? undefined : `${theme.palette.error.main} !important`,
      color:
        disabled ? undefined : (
          `${theme.palette.primary.contrastText} !important`
        ),
    },
  },
}));

export const usePrintButtonStyles = makeStyles()((theme, {margin}) => ({
  printButton: {
    marginTop: margin?.top ? theme.spacing(margin.top) : undefined,
    marginBottom: margin?.bottom ? theme.spacing(margin.bottom) : undefined,
    marginLeft: margin?.left ? theme.spacing(margin.left) : undefined,
    marginRight: margin?.right ? theme.spacing(margin.right) : undefined,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    "@media print": {
      display: "none !important",
    },
  },
}));

export const useSubmitStyles = makeStyles()(
  (theme, {inline, margin, styleHover}) => ({
    root: {
      display: inline ? undefined : "flex",
      alignItems: "center",
    },
    wrapper: {
      position: "relative",
    },
    submit: {
      minWidth: 85,
      marginTop: margin?.top ? theme.spacing(margin.top) : undefined,
      marginBottom: margin?.bottom ? theme.spacing(margin.bottom) : undefined,
      marginLeft: margin?.left ? theme.spacing(margin.left) : undefined,
      marginRight: margin?.right ? theme.spacing(margin.right) : undefined,
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        backgroundColor:
          styleHover ? `${theme.palette.primary.main} !important` : undefined,
        color:
          styleHover ?
            `${theme.palette.primary.contrastText} !important` :
            undefined,
      },
    },
  }),
);

export const useDeleteButtonStyles = makeStyles()(() => ({
  root: {
    backgroundColor: "#af4242",
    borderColor: "#af4242",
    "&:hover": {
      backgroundColor: "#823333",
      borderColor: "#823333",
    },
  },
}));

export const useRefetchButtonStyles = makeStyles()((theme) => ({
  refetch: {
    "&.MuiIconButton-root:hover": {
      backgroundColor: "unset",
    },
  },
}));

export const useReloadButtonStyles = makeStyles()((theme) => ({
  reloadButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: `${theme.palette.error.main} !important`,
      borderColor: `${theme.palette.error.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
}));

export const useFilterButtonStyles = makeStyles()((theme) => ({
  filterButton: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: "0.875rem",
  },
  filterButtonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterGroupDivider: {
    borderColor: theme.palette.mode === "dark" ? "#515151" : "#e0e0e0",
    borderWidth: "0.025em",
    display: "inline",
  },
}));

export const useZoomButtonStyles = makeStyles()((theme) => ({
  root: {
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
  },
  zoom: {
    minWidth: theme.spacing(15),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      borderColor: theme.palette.primary.main,
    },
    fontSize: "12px",
  },
}));

import React, {useEffect, useState} from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
  fromPromise,
} from "@apollo/client";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {ApolloLink} from "@apollo/client";
import PropTypes from "prop-types";
import {useAuth} from "../components/authentication/Context/AuthProvider";
import {getMainDefinition} from "@apollo/client/utilities";
import typePolicies from "../graphql/typePolicies";

const graphqlURL = process.env.GRAPHQL_API;

const GraphQLClientProvider = ({children}) => {
  const {authSession, refreshSession, orgName} = useAuth();
  const [apolloClient, setApolloClient] = useState();

  useEffect(() => {
    if (!authSession?.AccessToken) return;

    const httpLink = createHttpLink({
      uri: `${graphqlURL}/graphql`,
    });
    const authLink = setContext(async (_, {headers}) => {
      return {
        headers: {
          ...headers,
          orgName: orgName,
          Authorization: `Bearer ${authSession.IdToken}`,
        },
      };
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${graphqlURL.replace(/http/, "ws")}/graphql?orgName=${orgName}`,
        connectionParams: {
          authToken: `Bearer ${authSession.IdToken}`,
        },
      }),
    );

    const onErrorLink = onError(
      ({response, operation, forward, networkError}) => {
        if (networkError?.statusCode === 401) {
          return fromPromise(
            refreshSession().then((result) => {
              const token = result.IdToken;
              const oldHeaders = operation.getContext().headers;

              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${token}`,
                },
              });

              return forward(operation);
            }),
          );
        }
      },
    );

    const splitLink = split(
      ({query}) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink,
    );

    const links = ApolloLink.from([onErrorLink, authLink, splitLink]);

    const client = new ApolloClient({
      link: links,
      cache: new InMemoryCache({
        typePolicies,
      }),
    });

    setApolloClient(client);
  }, [authSession]);

  if (apolloClient) {
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
  }

  return null;
};

GraphQLClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {GraphQLClientProvider};

import React, {useState, useContext, createContext} from "react";
import PropTypes from "prop-types";

export const OverviewContext = createContext(null);

export const OverviewProvider = ({children}) => {
  const [step, setStep] = useState(0);
  const [showingOverview, setShowingOverview] = useState(false);

  const nextStep = () => setStep(step + 1);

  const previousStep = () => setStep(step - 1);

  const closeOverview = () => {
    setShowingOverview(false);
    setStep(0);
  };

  return (
    <OverviewContext.Provider
      value={{
        step,
        setStep,
        showingOverview,
        setShowingOverview,
        nextStep,
        previousStep,
        closeOverview,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

OverviewProvider.propTypes = {
  children: PropTypes.any,
};

export const useOverview = () => {
  return useContext(OverviewContext);
};

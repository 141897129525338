import React from "react";
import PropTypes from "prop-types";
import {Collapse, ListItemButton, ListItemText, List} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {Can} from "../../context/AuthzProvider";
import {useSidebar} from "./Context/SidebarProvider";
import UpgradeAdornment from "../UpgradeAdornment";
import SidebarIcon from "./SidebarIcon";
import {useSidebarItemStyles} from "./sidebarStyles";
import {includes} from "underscore";

const SidebarMenu = ({
  children,
  hide,
  icon,
  needUpgrade,
  relatedModelName,
  text,
}) => {
  if (hide) return null;

  const {isSidebarHidden, sidebarMenuOpen, toggleSidebarMenu} = useSidebar();
  const {classes} = useSidebarItemStyles({isSidebarHidden});

  const isOpen = includes(sidebarMenuOpen, text);

  return (
    <Can I="update" a={relatedModelName}>
      <ListItemButton
        onClick={() => !needUpgrade && toggleSidebarMenu(text)}
        className={classes.item}
        id={`sidebar-menu-${text}`}
      >
        <SidebarIcon icon={icon} text={text} />
        <ListItemText
          primary={text}
          className={classes.itemText}
          primaryTypographyProps={{variant: "body2"}}
        />
        {needUpgrade ?
          <UpgradeAdornment fontSize="small" item={text} /> :
          isOpen ?
            <ExpandLess /> :
            <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.menuList}>
          {children}
        </List>
      </Collapse>
    </Can>
  );
};

SidebarMenu.propTypes = {
  children: PropTypes.any.isRequired,
  hide: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  needUpgrade: PropTypes.bool,
  relatedModelName: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default SidebarMenu;

export const powerbarItems = [
  {
    action: "Add Obligation",
    key: "ctrl+alt+o",
    path: "/obligations/add",
    relatedModelName: "Obligation",
  },
  {
    action: "Add Task to Library",
    key: "ctrl+alt+t",
    path: "/tasks/add",
    relatedModelName: "Task",
  },
  {
    action: "Complete My Actions",
    key: "ctrl+alt+a",
    path: "/actions/list",
    relatedModelName: "Incident",
  },
  {
    action: "Create Dataset",
    key: "ctrl+alt+d",
    path: "/datasets/add",
    relatedModelName: "Dataset",
  },
  {
    action: "Create Exercise",
    key: "ctrl+alt+e",
    path: "/incidents/exercise",
    relatedModelName: "Incident",
  },
  {
    action: "Create Incident",
    key: "ctrl+alt+i",
    path: "/incidents/add",
    relatedModelName: "Incident",
  },
  {
    action: "Invite New User",
    key: "ctrl+alt+n",
    path: "/users/add",
    relatedModelName: "User",
  },
  {action: "Manage Your Profile", key: "ctrl+alt+y", path: "/userProfile"},
  {
    action: "Send Message",
    key: "ctrl+alt+m",
    path: "/messaging/new",
    relatedModelName: "Message",
  },
  {
    action: "View Data Schema",
    key: "ctrl+alt+s",
    path: "/dataOptions/list",
    relatedModelName: "IncidentType",
  },
  {
    action: "View Roles",
    key: "ctrl+alt+r",
    path: "/roles",
    relatedModelName: "Role",
  },
  {
    action: "View Users",
    key: "ctrl+alt+u",
    path: "/users/list",
    relatedModelName: "User",
  },
];

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import {
  sanitizeLogRocket,
  logRocketRequestSanitizer,
  logRocketResponseSanitizer,
} from "./resources/logRocket";
import {Helmet} from "react-helmet";
import {buildCSP} from "./resources/csp";
import "@fontsource/oswald/300.css";
import "@fontsource/oswald/400.css";
import "@fontsource/oswald/500.css";
import "@fontsource/oswald/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const ENVIRONMENT_NAME = process.env.ENVIRONMENT_NAME;
const isProduction = ENVIRONMENT_NAME === "production";
const logRocketAppId =
  isProduction ? "cmqc3w/breachrx" : "cmqc3w/breachrx-development";

if (ENVIRONMENT_NAME !== "localdev") {
  LogRocket.init(logRocketAppId, {
    dom: {
      inputSanitizer: sanitizeLogRocket(isProduction),
      textSanitizer: sanitizeLogRocket(isProduction),
    },
    network: {
      requestSanitizer:
        sanitizeLogRocket(isProduction) ? logRocketRequestSanitizer : undefined,
      responseSanitizer:
        sanitizeLogRocket(isProduction) ?
          logRocketResponseSanitizer :
          undefined,
    },
  });
  setupLogRocketReact(LogRocket);
}

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      {ENVIRONMENT_NAME !== "localdev" && (
        <meta httpEquiv="Content-Security-Policy" content={buildCSP()} />
      )}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="description"
        content="BreachRx | Dynamic Incident Readiness & Response"
      />
      <link rel="icon" href="/cropped-favicon-32x32.png" />
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById("app"),
);

if (import.meta.hot) import.meta.hot.accept();

import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Divider, Typography, useTheme} from "@mui/material";
import {useAuth} from "./Context/AuthProvider";
import {toBase64URL} from "../../resources/utils/encoding";
import {isEmpty, keys, reject} from "underscore";
import {useAuthStyles} from "./AuthStyles";

export const ProviderLogins = () => {
  const {altAuthProviders, hasAuthProviders} = useAuth();
  const authProviderCount = reject(
    keys(altAuthProviders),
    (key) => !altAuthProviders[key],
  ).length;
  const {classes} = useAuthStyles({authProviderCount});
  const theme = useTheme();

  const storeRememberMePrefAndRedirect = (provider) => {
    window.sessionStorage.setItem("rememberMe", "true");
    const state =
      window.location.pathname.length > 1 ?
        `&state=${toBase64URL(window.location.pathname)}` :
        "";
    window.location.href = `${altAuthProviders[provider]}${state}`;
  };

  if (!hasAuthProviders) return null;

  const ProviderButton = ({icon, label, urlLabel}) => {
    if (isEmpty(altAuthProviders?.[urlLabel])) return null;
    return (
      <Button
        variant="outlined"
        size="large"
        className={classes.providerButton}
        fullWidth
        onClick={() => storeRememberMePrefAndRedirect(urlLabel)}
        id={`${label.toLowerCase()}-provider-login`}
      >
        <img className={classes.providerLogo} src={icon} />
        {label}
      </Button>
    );
  };
  ProviderButton.propTypes = {
    icon: PropTypes.any,
    label: PropTypes.string,
    urlLabel: PropTypes.string,
  };

  const oneLoginLogoDetail =
    theme.palette.mode === "dark" ?
      require("~/public/integrations/onelogin-detail.png") :
      require("~/public/integrations/onelogin-detail-reverse.png");

  return (
    <>
      <Divider sx={{marginTop: 2}}>
        <Typography variant="caption">Sign in with</Typography>
      </Divider>
      <Box py={3} className={classes.buttonRow}>
        <ProviderButton
          icon={require("~/public/integrations/google-detail.svg")}
          label="Google"
          urlLabel="googleUrl"
        />
        <ProviderButton
          icon={require("~/public/integrations/microsoft-detail.png")}
          label={authProviderCount === 1 ? "Microsoft Entra ID" : "Microsoft"}
          urlLabel="msEntraUrl"
        />
        <ProviderButton
          icon={require("~/public/integrations/okta-detail.png")}
          label="Okta"
          urlLabel="oktaUrl"
        />
        <ProviderButton
          icon={oneLoginLogoDetail}
          label="OneLogin"
          urlLabel="oneLoginUrl"
        />
        <ProviderButton
          icon={require("~/public/integrations/ping-detail.png")}
          label="Ping"
          urlLabel="pingUrl"
        />
      </Box>
      <Divider>
        <Typography variant="caption">or</Typography>
      </Divider>
    </>
  );
};

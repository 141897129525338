import React from "react";
import {Button} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import AssistantPhotoOutlinedIcon from "@mui/icons-material/AssistantPhotoOutlined";
import {keys} from "underscore";
import {CenteredBox} from "../CenteredBox";
import {useSidebar} from "../sidebar/Context/SidebarProvider";
import StyledTooltip from "../tooltips/StyledTooltip";
import {OverviewDialog} from "./OverviewDialog";
import {OverviewProvider, useOverview} from "./OverviewContext";
import {overviewData} from "./overviewData";
import {useSidebarIconStyles} from "../sidebar/sidebarStyles";

const pagesWithOverviews = keys(overviewData);

const useOverviewButtonStyles = makeStyles()((theme) => ({
  overviewButtonContainer: {
    height: "100%",
    width: "inherit",
    verticalAlign: "middle",
    justifyContent: "center",
    display: "flex",
  },
}));

export const Overview = () => (
  <OverviewProvider>
    <OverviewContent />
  </OverviewProvider>
);

const OverviewContent = () => {
  const {showingOverview, setShowingOverview} = useOverview();
  const {currentPage, isSidebarHidden} = useSidebar();
  const {classes} = useOverviewButtonStyles();
  const {classes: iconClasses} = useSidebarIconStyles({isSidebarHidden});

  const currentPageDisplay = currentPage?.split("/")[0]?.replace("/", "");
  const pageTitle =
    currentPageDisplay === "dashboard" ? "BreachRx" :
    currentPageDisplay === "dataOptions" ? "Data Options" :
    currentPageDisplay?.charAt(0).toUpperCase() +
      currentPageDisplay?.slice(1);

  const buttonId = `${currentPageDisplay}-overview`;
  const overviewControl =
    isSidebarHidden ?
      <StyledTooltip title={`${pageTitle} Overview`} placement="right">
        <AssistantPhotoOutlinedIcon
          onClick={() => setShowingOverview(true)}
          id={buttonId}
          className={iconClasses.listItemIcon}
        />
      </StyledTooltip> :
      <Button
        size="small"
        variant="outlined"
        color="grey"
        onClick={() => setShowingOverview(true)}
        id={buttonId}
      >
        {pageTitle} Overview
      </Button>;

  return (
    pagesWithOverviews.includes(currentPageDisplay) && (
      <>
        <div
          className={classes.overviewButtonContainer}
          id="overview-container"
        >
          <CenteredBox>{overviewControl}</CenteredBox>
        </div>
        {showingOverview && <OverviewDialog page={currentPageDisplay} />}
      </>
    )
  );
};

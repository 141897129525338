import {isEmpty, map, sortBy} from "underscore";

const addUserOptionLabels = (data, options = {}) => {
  const {shouldSort} = options;
  const shapedUsers = map(data, (each) => {
    const userName = each.fullName || each.nickName;
    return {
      ...each,
      optionLabel: `${userName ? `${userName} ` : ""}(${each.email})`,
    };
  });

  if (shouldSort) {
    return sortBy(shapedUsers, (eachUser) =>
      eachUser?.optionLabel?.toLowerCase(),
    );
  }
  return shapedUsers;
};

const diffUsers = (existingUsers, newUsers) => {
  if (!Array.isArray(existingUsers) || typeof newUsers !== "object") {
    throw Error("Invalid arguments!");
  }
  const existinguserIds = existingUsers.map((userRole) => {
    return userRole.user.id;
  });
  const newuserIds = newUsers.map((newUser) => {
    return newUser.userId;
  });

  return {
    usersToDelete: existinguserIds.filter(
      (existinguserId) => !newuserIds.includes(existinguserId),
    ),
    usersToCreate: newuserIds
      .filter((newuserId) => !existinguserIds.includes(newuserId))
      .map((remaininguserId) => {
        return {userId: remaininguserId};
      }),
  };
};

const getDisplayName = (user, skipNickname, addEmail = false) => {
  if (addEmail) {
    const nameToUse =
      skipNickname ? user?.fullName : user?.nickname || user.fullName;
    return !isEmpty(nameToUse) ?
      `${nameToUse} (${user.email})` :
      user?.email || undefined;
  }
  if (skipNickname) return user?.fullName || user?.email || undefined;
  return user?.nickname || user?.fullName || user?.email || undefined;
};

export {addUserOptionLabels, getDisplayName, diffUsers};

import React from "react";
import {Grid, Link} from "@mui/material";
import {useFormContext} from "react-hook-form";
import axios from "axios";
import {useAuth} from "./Context/AuthProvider";
import {isEmpty} from "underscore";
import {useAuthStyles} from "./AuthStyles";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const ForgotPasswordLink = () => {
  const {classes} = useAuthStyles();
  const formMethods = useFormContext();
  const {setErrorMessage, setIsAuthLoading, setAuthSession, orgName} =
    useAuth();

  const sendForgotPasswordEmail = async () => {
    setErrorMessage("");
    setIsAuthLoading(true);
    const username = formMethods.getValues("username");

    if (isEmpty(username)) {
      setErrorMessage("Email required to recover password.");
      setIsAuthLoading(false);
      return;
    }

    const genericResponse = `Thank you for submitting your password reset request. An email with 
    further instructions has been sent to your registered email address.`;
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/password/forgot`, {
        Username: username,
        orgName,
      });
      setAuthSession();
      setErrorMessage(genericResponse);
    } catch (error) {
      if (
        (error?.message ?? error.response?.data?.message) === "Network Error"
      ) {
        setErrorMessage(genericResponse);
      } else {
        setErrorMessage(
          error?.message ??
            error.response?.data?.message ??
            JSON.stringify(error),
        );
      }
    } finally {
      setIsAuthLoading(false);
    }
  };

  return (
    <Grid className={classes.forgotPasswordLink}>
      <Link
        onClick={sendForgotPasswordEmail}
        underline="hover"
        fontFamily="Roboto"
        fontSize={14}
      >
        Forgot Password?
      </Link>
    </Grid>
  );
};

export default ForgotPasswordLink;

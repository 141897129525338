import React from "react";
import PropTypes from "prop-types";
import {Button, IconButton} from "@mui/material";
import {usePrintButtonStyles} from "./buttonStyles";
import {isFunction} from "underscore";

const PrintButton = ({children, onClick, icon, margin, title, disabled}) => {
  const {classes} = usePrintButtonStyles({margin});

  const handleClick = () => {
    try {
      const oldTitle = document.title;
      if (title) document.title = title;

      if (isFunction(onClick)) onClick();
      else window.print();

      if (title) document.title = oldTitle;
    } catch (error) {
      console.error(error);
    }
  };

  if (icon) {
    return (
      <IconButton
        id="print"
        onClick={handleClick}
        disabled={disabled}
        sx={{":hover": {background: "inherit"}}}
      >
        {icon}
      </IconButton>
    );
  }

  return (
    <Button
      id="print"
      color="primary"
      variant="outlined"
      className={classes.printButton}
      onClick={handleClick}
      disabled={disabled}
    >
      {children || "Print"}
    </Button>
  );
};

PrintButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  margin: PropTypes.object,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default PrintButton;

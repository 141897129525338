import React, {useState, useEffect, useContext, createContext} from "react";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import {useMediaQuery, useTheme} from "@mui/material";
import {useLazyQuery, useQuery, useSubscription} from "@apollo/client";
import {GET_ACTION_COUNT_FOR_USER} from "../../../graphql/actionQueries";
import {
  GET_UNREAD_MESSAGE_COUNT,
  SUBSCRIBE_TO_USER_MESSAGES,
} from "../../../graphql/messageQueries";
import {GET_ACTION_PHASES} from "../../../graphql/phaseQueries";
import {ALERT} from "../../../resources/environment";
import {handleSubscriptionError} from "../../../resources/utils/error";
import {useUser} from "../../user/Context/UserProvider";
import {includes, isEmpty, max, without} from "underscore";

export const SidebarContext = createContext(null);

export const SidebarProvider = ({children}) => {
  const {user} = useUser();
  const location = useLocation();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const printQuery = useMediaQuery("print");
  const [currentPage, setCurrentPage] = useState();
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const openMenusOnStart = ["Respond"];
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(openMenusOnStart);

  const {data: subscribedMessageData} = useSubscription(
    SUBSCRIBE_TO_USER_MESSAGES,
    {
      variables: {
        userId: parseInt(user.id, 10),
      },
      onError: (error) => handleSubscriptionError(error, "User messages"),
    },
  );

  const {data: unreadData, refetch: refetchMessage} = useQuery(
    GET_UNREAD_MESSAGE_COUNT,
    {
      variables: {userId: user.id},
      onError: (error) => {
        ALERT.error("Could not get Message data, please try again.");
      },
    },
  );

  const {data: actionPhases} = useQuery(GET_ACTION_PHASES, {
    onError: (error) => console.error(error),
  });

  const [
    getMyActionCount,
    {
      data: myActionCount,
      loading: loadingMyActionCount,
      refetch: refetchMyActionCount,
    },
  ] = useLazyQuery(GET_ACTION_COUNT_FOR_USER, {
    onError: (error) => console.error(error),
  });

  useEffect(() => {
    const path = location.pathname;
    const page = `/${path.split("/")[1]}`;
    setCurrentPage(page);
    user?.wantsSidebarClosed ?
      setIsSidebarHidden(true) :
      setIsSidebarHidden(false);
  }, []);

  const setMenuOpenIfNeeded = (menu, paths, page) => {
    if (includes(paths, page) && !includes(sidebarMenuOpen, menu)) {
      setSidebarMenuOpen((current) => [...current, menu]);
    }
  };

  const whichSidebarEntryToHighlight = (page, subpage) => {
    if (["reports"].includes(page)) return `${page}/${subpage}`;
    if (["compliance"].includes(page)) {
      if (!["summary", "plan"].includes(subpage)) return "compliance/summary";
      return `${page}/${subpage}`;
    }
    return page;
  };

  useEffect(() => {
    const path = location.pathname;
    const page = `${path.split("/")[1]}`;
    const subpage = `${path.split("/")?.[2]}`;
    setMenuOpenIfNeeded("Respond", ["actions, incidents"], page);
    setMenuOpenIfNeeded("Compliance", ["compliance"], page);
    setMenuOpenIfNeeded("Report", ["reports"], page);
    setMenuOpenIfNeeded(
      "Sources",
      ["obligations", "tasks", "playbooks", "datasets", "data"],
      page,
    );
    setMenuOpenIfNeeded(
      "Settings",
      ["users", "roles", "organization", "notifications"],
      page,
    );
    setMenuOpenIfNeeded("Integrations", ["integrations", "api"], page);
    setCurrentPage(whichSidebarEntryToHighlight(page, subpage));
  }, [location.pathname]);

  useEffect(
    () => xsScreen && !printQuery && setIsSidebarHidden(true),
    [xsScreen, printQuery],
  );

  useEffect(refetchMessage, [subscribedMessageData]);

  useEffect(() => {
    if (!isEmpty(actionPhases) && !loadingMyActionCount) {
      const lastActionPhase = max(
        actionPhases.actionPhases,
        (actionPhase) => actionPhase.ordering,
      );
      getMyActionCount({
        variables: {userId: user.id, lastActionPhase: lastActionPhase.id},
      });
    }
  }, [actionPhases]);

  const toggleSidebar = () => setIsSidebarHidden(!isSidebarHidden);

  const toggleSidebarMenu = (item) => {
    const isItemOpen = includes(sidebarMenuOpen, item);
    if (isItemOpen) setSidebarMenuOpen((current) => without(current, item));
    else setSidebarMenuOpen((current) => [...current, item]);
  };

  return (
    <SidebarContext.Provider
      value={{
        // state
        currentPage,
        setCurrentPage,
        isSidebarHidden,
        setIsSidebarHidden,
        sidebarMenuOpen,
        setSidebarMenuOpen,

        // queries
        myActionCount,
        unreadData,

        // functions
        toggleSidebar,
        toggleSidebarMenu,
        refetchMyActionCount,
        refetchMessage,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.any,
};

export const useSidebar = () => {
  return useContext(SidebarContext);
};

import React from "react";
import PropTypes from "prop-types";
import {responsiveFontSizes, ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import {TssCacheProvider} from "tss-react";
import createCache from "@emotion/cache";

const muiCache = createCache({key: "mui", prepend: true});
const tssCache = createCache({key: "tss"});

export const ConsolidatedThemeProvider = ({activeTheme, children}) => {
  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <ThemeProvider theme={responsiveFontSizes(activeTheme)}>
          {children}
        </ThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
};

ConsolidatedThemeProvider.propTypes = {
  activeTheme: PropTypes.object,
  children: PropTypes.any,
};

import React from "react";
import {useNetworkError} from "./Context/NetworkErrorProvider";
import {Container, Grid, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {shield} from "../../resources/environment";
import PageTitle from "../PageTitle";

const useErrorStyles = makeStyles()((theme) => ({
  logo: {
    backgroundImage: `url(${shield})`,
    height: 130,
    width: 106,
    backgroundRepeat: "no-repeat",
    margin: "auto",
  },
  middle: {
    paddingTop: "15vh",
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  spaceTop: {
    paddingTop: "0.75rem",
    "& span": {
      "&:after": {
        content: "' '",
      },
    },
  },
}));

const NetworkErrorPage = () => {
  const {networkError} = useNetworkError();
  const {classes} = useErrorStyles();

  if (!networkError) return null;

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} className={classes.middle}>
        <Grid item xs={4}>
          <div id="logo" className={classes.logo} />
        </Grid>
        <Grid item xs={8} className={classes.center}>
          <Grid>
            <PageTitle>
              The BreachRx platform is currently unavailable.
            </PageTitle>
            <Typography noWrap className={classes.spaceTop}>
              <span>Platform status updates can be found</span>
              <a
                href="https://breachrx.statuspage.io/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </Typography>
            <Typography noWrap className={classes.spaceTop}>
              <span>Please contact</span>
              <a href="mailto:support@breachrx.com">support@breachrx.com</a> for
              more information.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export {NetworkErrorPage};

import React from "react";
import PropTypes from "prop-types";
import {Grid} from "@mui/material";
import {makeStyles} from "tss-react/mui";

const useFormGuideStyles = makeStyles()((theme, {isEditor, disabled}) => ({
  guideWrapper: {
    marginTop: isEditor ? theme.spacing(2) : undefined,
  },
  required: {
    fontStyle: "italic",
    fontWeight: "bold",
    color:
      disabled ? theme.palette.text.disabled :
      theme.palette.mode === "dark" ? theme.palette.grey["400"] :
      theme.palette.grey["800"],
  },
  guide: {
    fontStyle: "italic",
    textAlign: "justify",
    color:
      disabled ? theme.palette.text.disabled :
      theme.palette.mode === "dark" ? theme.palette.grey["700"] :
      theme.palette.grey["400"],
  },
}));

const FormGuide = ({
  guide,
  customOptionality,
  disabled,
  isEditor,
  needUpgrade,
  hideOptionality,
  required,
  width,
  isText,
}) => {
  if (!guide) return null;
  const {classes} = useFormGuideStyles({
    disabled: disabled,
    isEditor: isText || isEditor,
  });
  const optionalityText =
    customOptionality ? `(${customOptionality})` :
    required && !needUpgrade ? "(required)" :
    "";

  const GuideText = ({children, className, hide}) => {
    if (hide) return null;
    return <span className={className}>{children}</span>;
  };

  GuideText.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    hide: PropTypes.bool,
  };

  return (
    <Grid item xs={width || 3} className={classes.guideWrapper}>
      <GuideText className={classes.guide}>{guide}</GuideText>
      <GuideText
        className={required ? classes.required : classes.guide}
        hide={hideOptionality}
      >
        {` ${optionalityText}`}
      </GuideText>
    </Grid>
  );
};

FormGuide.propTypes = {
  guide: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customOptionality: PropTypes.string,
  disabled: PropTypes.bool,
  hideOptionality: PropTypes.bool,
  isEditor: PropTypes.bool,
  needUpgrade: PropTypes.bool,
  required: PropTypes.bool,
  width: PropTypes.number,
  isText: PropTypes.bool,
};

export default FormGuide;

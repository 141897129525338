import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";

const AICooldownContext = createContext({
  cooldownLeft: 0,
  setCooldownLeft: () => {},
});

const AICooldownProvider = ({children}) => {
  const [cooldownLeft, setCooldownLeft] = useState(0);

  useEffect(() => {
    let interval;

    if (cooldownLeft > 0) {
      interval = setInterval(() => {
        setCooldownLeft((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [cooldownLeft]);

  return (
    <AICooldownContext.Provider value={{cooldownLeft, setCooldownLeft}}>
      {children}
    </AICooldownContext.Provider>
  );
};

AICooldownProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAICooldown = () => {
  return useContext(AICooldownContext);
};

export default AICooldownProvider;

import React from "react";
import {Drawer, Paper, Toolbar} from "@mui/material";
import {Overview} from "../overview/Overview";
import {sidebarClass} from "../../resources/environment";
import {useSidebar} from "./Context/SidebarProvider";
import {SidebarContent} from "./SidebarContent";
import {useSidebarStyles} from "./sidebarStyles";

export const Sidebar = () => {
  const {classes} = useSidebarStyles();
  const {isSidebarHidden} = useSidebar();
  const sidebarHiddenClass = isSidebarHidden ? `${sidebarClass}` : "";

  return (
    <div className={`${classes.root} ${sidebarHiddenClass}`} id="sidebar">
      <Drawer
        className={`${classes.drawer} ${sidebarHiddenClass}`}
        variant="permanent"
        classes={{paper: classes.drawerPaper}}
      >
        <Toolbar />
        <div id="sidebar-content">
          <SidebarContent />
        </div>
      </Drawer>
      <Paper className={`${classes.overviewPaper} ${sidebarHiddenClass}`}>
        <Overview />
      </Paper>
    </div>
  );
};

import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {CenteredBox} from "../CenteredBox";
import StyledDialog from "../dialogs/StyledDialog";
import {useOverview} from "./OverviewContext";
import {overviewData} from "./overviewData";

const useOverviewDialogStyles = makeStyles()((theme) => ({
  content: {
    minHeight: "400px",
    backgroundColor: theme.palette.grey["300"],
  },
}));

export const OverviewDialog = ({page}) => {
  const {step, showingOverview, nextStep, previousStep, closeOverview} =
    useOverview();
  const {classes} = useOverviewDialogStyles();

  const pageData = overviewData[page];
  const currentStep = pageData?.[step];
  const stepCount = pageData?.length || 0;

  const stepDisplay = (
    <Typography variant="h6" id="step">{`${step + 1}/${stepCount}`}</Typography>
  );

  const actionButtons = (
    <Box m={2}>
      {step > 0 && (
        <Button
          variant="outlined"
          color="primary"
          style={{marginRight: ".5em"}}
          onClick={() => previousStep()}
          id="back-overview"
        >
          Back
        </Button>
      )}
      {step < stepCount - 1 && (
        <Button
          variant="contained"
          color="primary"
          onClick={nextStep}
          id="next-overview"
        >
          Next
        </Button>
      )}
      {step + 1 === stepCount && (
        <Button
          variant="contained"
          color="primary"
          onClick={closeOverview}
          id="close-overview"
        >
          Close
        </Button>
      )}
    </Box>
  );

  return (
    <StyledDialog
      isOpen={showingOverview}
      title={currentStep.title}
      onClose={closeOverview}
    >
      <Box>
        <CenteredBox extraClasses={classes.content}>
          {currentStep.content}
        </CenteredBox>
        <CenteredBox pt={2}>
          <center>{currentStep.description}</center>
        </CenteredBox>
        <CenteredBox>
          {actionButtons}
          {stepDisplay}
        </CenteredBox>
      </Box>
    </StyledDialog>
  );
};

OverviewDialog.propTypes = {
  page: PropTypes.string,
};

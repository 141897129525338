import React from "react";
import PropTypes from "prop-types";
import {useTheme} from "@mui/material";
import StyledTooltip from "../tooltips/StyledTooltip";
import {ErrorOutline} from "@mui/icons-material";

const OverdueErrorIcon = ({
  className,
  isOverdue,
  title = "Overdue",
  placement,
}) => {
  const theme = useTheme();

  if (!isOverdue) return null;

  return (
    <StyledTooltip
      title={title}
      backgroundColor={theme.palette.error.main}
      placement={placement}
    >
      <ErrorOutline className={className} fontSize="small" color="error" />
    </StyledTooltip>
  );
};

OverdueErrorIcon.propTypes = {
  isOverdue: PropTypes.bool,
  className: PropTypes.any,
  title: PropTypes.string,
  placement: PropTypes.string,
};

export default OverdueErrorIcon;

import React from "react";
import PropTypes from "prop-types";
import {ALERT} from "../resources/environment";
import {Typography} from "@mui/material";
import Loader from "./Loader";

const Error = ({error}) => {
  console.error(JSON.stringify(error));
  const defaultError = error?.message || "Please try again later";
  try {
    if (error.networkError.statusCode !== 401) {
      ALERT.error(error.networkError.result.errors[0].message);
    }
  } catch (e) {
    ALERT.error(defaultError);
  }
  if (error?.networkError?.statusCode === 401) {
    return <Loader />;
  }
  return <Typography variant="body1">Error: {defaultError}</Typography>;
};

Error.propTypes = {
  error: PropTypes.object,
};

export default Error;

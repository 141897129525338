import React from "react";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import {useReloadButtonStyles} from "./buttonStyles";

const ReloadButton = ({children, onReload}) => {
  const {classes} = useReloadButtonStyles();

  const onClick = () => {
    if (onReload) onReload();
    location.reload();
  };

  return (
    <Button
      type="button"
      id="reload"
      variant="outlined"
      onClick={onClick}
      className={classes.reloadButton}
    >
      {children || "Reload"}
    </Button>
  );
};

ReloadButton.propTypes = {
  children: PropTypes.any,
  onReload: PropTypes.func,
};

export default ReloadButton;

import React from "react";
import PropTypes from "prop-types";
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";

const usePageTitleStyles = makeStyles()((theme, {inline}) => ({
  title: {
    verticalAlign: inline ? "middle" : undefined,
    lineHeight: inline ? 1.75 : undefined,
    color: theme.palette.grey[theme.palette.mode === "dark" ? "200" : "900"],
    userSelect: "none",
    cursor: "default",
  },
}));

const PageTitle = ({children, count, id, inline}) => {
  if (!children) return null;
  const {classes} = usePageTitleStyles({inline: inline});

  return (
    <Grid item xs={inline ? false : 12}>
      <Typography variant="h5" className={classes.title} id={id}>
        {children}
        {count ? ` (${count})` : ""}
      </Typography>
    </Grid>
  );
};

PageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  count: PropTypes.number,
  id: PropTypes.string,
  inline: PropTypes.bool,
};

export default PageTitle;

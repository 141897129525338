import React from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/material";

export const CenteredBox = ({
  children,
  extraClasses = "",
  onClick,
  ...others
}) => (
  <Box
    onClick={onClick}
    sx={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    }}
    className={extraClasses}
    {...others}
  >
    {children}
  </Box>
);

CenteredBox.propTypes = {
  children: PropTypes.any,
  extraClasses: PropTypes.string,
  onClick: PropTypes.func,
};

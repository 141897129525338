import React from "react";
import PropTypes from "prop-types";
import {CircularProgress} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {shield} from "../resources/environment";
import {CenteredBox} from "./CenteredBox";

const useLoaderStyles = makeStyles()((theme, {fullViewingHeight}) => ({
  loader: {
    backgroundColor: theme.palette.background.default,
    height: fullViewingHeight ? "100vh" : undefined,
    paddingTop: theme.spacing(10),
  },
  logo: {
    backgroundImage: `url(${shield})`,
    height: 130,
    width: 106,
    marginBottom: 30,
    backgroundRepeat: "no-repeat",
    caretColor: "transparent",
  },
}));

const Loader = ({fullViewingHeight = false, showLogo = true}) => {
  const {classes} = useLoaderStyles({fullViewingHeight});
  return (
    <div id="loader" className={classes.loader}>
      <CenteredBox>
        {showLogo && <div id="logo" role="logo" className={classes.logo} />}
        <CircularProgress
          id="circular-progress"
          data-testid="loader"
          color="primary"
        />
      </CenteredBox>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  fullViewingHeight: PropTypes.bool,
  showLogo: PropTypes.bool,
};

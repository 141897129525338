import {gql} from "@apollo/client";

export const UPDATE_VANTA_INTEGRATION = gql`
  mutation UpdateVantaIntegration($isEnabled: Boolean) {
    updateVantaIntegration(data: {isEnabled: $isEnabled}) {
      name
      configuration
    }
  }
`;

export const DELETE_VANTA_INTEGRATION = gql`
  mutation DeleteVantaIntegration {
    deleteVantaIntegration {
      name
      configuration
    }
  }
`;

export const SET_VANTA_ACCESS_TOKEN = gql`
  mutation SetVantaAccessTokens($code: String!) {
    setVantaAccessTokens(code: $code) {
      name
      configuration
    }
  }
`;

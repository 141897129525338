import React from "react";
import PropTypes from "prop-types";
import {ListItemIcon} from "@mui/material";
import StyledTooltip from "../tooltips/StyledTooltip";
import {useSidebar} from "./Context/SidebarProvider";
import {useSidebarIconStyles} from "./sidebarStyles";

const SidebarIcon = ({icon, text}) => {
  const {isSidebarHidden} = useSidebar();
  const {classes} = useSidebarIconStyles({isSidebarHidden});
  if (isSidebarHidden) {
    return (
      <StyledTooltip title={text} placement="right">
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      </StyledTooltip>
    );
  }
  return (
    <ListItemIcon className={classes.listItemIcon}>
      {React.cloneElement(icon, {fontSize: "small"})}
    </ListItemIcon>
  );
};

SidebarIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

export default SidebarIcon;

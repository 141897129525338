import {useEffect} from "react";
import {useMutation} from "@apollo/client";
import {useIntegrations} from "../IntegrationContext";
import {useNavigate} from "react-router-dom";
import {UPDATE_SLACK_INTEGRATION} from "../../../../graphql/integrationQueries";
import {SET_VANTA_ACCESS_TOKEN} from "../../../../graphql/integrations/vantaQueries";
import {SET_ZOOM_ACCESS_TOKEN} from "../../../../graphql/integrations/zoomQueries";
import {SET_JIRA_ACCESS_TOKEN} from "../../../../graphql/integrations/jiraQueries";
import {ALERT} from "../../../../resources/environment";
import {SET_SALESFORCE_ACCESS_TOKEN} from "../../../../graphql/integrations/salesforceQueries";

export const useOauthHook = () => {
  const {updateIntegrationFields, selectedFormData, setSelectedFormData} =
    useIntegrations();
  const navigate = useNavigate();

  const [updateSlackIntegration] = useMutation(UPDATE_SLACK_INTEGRATION, {
    onCompleted: (data) => {
      updateIntegrationFields(data);
      setSelectedFormData(data.updateSlackIntegration.configuration);
    },
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const identifier = queryParams.get("identifier");

    if (!!identifier) {
      navigate("/integrations/slack", {replace: true, search: ""});
      updateSlackIntegration({variables: {identifier}});
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const clientKey = queryParams.get("clientKey");

    if (!!clientKey) {
      setSelectedFormData({...selectedFormData, clientKey});
    }
  }, []);

  const [setSalesforceAccessTokens] = useMutation(SET_SALESFORCE_ACCESS_TOKEN, {
    onCompleted: (data) => {
      updateIntegrationFields(data.setSalesforceAccessTokens, false, true);
      setSelectedFormData(data.setSalesforceAccessTokens.configuration);
    },
  });

  const [setVantaAccessTokens] = useMutation(SET_VANTA_ACCESS_TOKEN, {
    onCompleted: (data) => {
      updateIntegrationFields(data);
      setSelectedFormData(data.setVantaAccessTokens.configuration);
    },
  });

  const [setZoomAccessTokens] = useMutation(SET_ZOOM_ACCESS_TOKEN, {
    onCompleted: (data) => {
      updateIntegrationFields(data);
      setSelectedFormData(data.setZoomAccessTokens.configuration);
    },
  });

  const [setJiraAccessTokens] = useMutation(SET_JIRA_ACCESS_TOKEN, {
    onCompleted: (data) => {
      updateIntegrationFields(data);
      setSelectedFormData(data.setJiraAccessTokens.configuration);
    },
    onError: (error) => {
      console.error(error);
      ALERT.error(error);
    },
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const type = queryParams.get("type");

    if (!!code) {
      switch (type) {
        case "jira":
          navigate(`/integrations/${type}`, {replace: true, search: ""});
          setJiraAccessTokens({variables: {code}});
          break;
        case "salesforce":
          navigate(`/integrations/${type}`, {replace: true, search: ""});
          setSalesforceAccessTokens({variables: {code}});
          break;
        case "vanta":
          navigate(`/integrations/${type}`, {replace: true, search: ""});
          setVantaAccessTokens({variables: {code}});
          break;
        case "zoom":
          navigate(`/integrations/${type}`, {replace: true, search: ""});
          setZoomAccessTokens({variables: {code}});
          break;
        default:
          navigate("/");
      }
    }
  }, []);
};

import React from "react";
import axios from "axios";
import {Grid, InputAdornment, Typography} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {isValidPhoneNumber} from "react-phone-number-input";
import CustomTextField from "../../forms/CustomTextField";
import {useAuthStyles} from "../AuthStyles";
import {SubmitButton, ReloadButton} from "../../buttons";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {mfaSchema} from "../schemas";
import {useAuth} from "../Context/AuthProvider";
import {useUser} from "../../user/Context/UserProvider";
import MFAContainer from "./MFAContainer";
import {clearCookieSession} from "../../../resources/cookies";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const MFASetupForm = () => {
  const {classes} = useAuthStyles();
  const {authSession, orgName, setErrorMessage, logout} = useAuth();
  const {user} = useUser();
  const formMethods = useForm({resolver: yupResolver(mfaSchema)});

  const updatePhone = async (phone) => {
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/mfa/set`, {
        accessToken: authSession.AccessToken,
        enableMfa: true,
        orgName,
        phone,
        username: user.email,
      });
      window.sessionStorage.setItem(
        "loginMessage",
        "Phone number successfully updated. Please log in.",
      );
    } catch (error) {
      setErrorMessage(
        "Something went wrong. Please contact your Administrator.",
      );
    } finally {
      logout();
    }
  };

  const setupMfa = async (formData) => {
    if (
      formData.phone &&
      !formData.phone.startsWith("+1") &&
      !isValidPhoneNumber(formData.phone) &&
      isValidPhoneNumber(formData.phone, "US")
    ) {
      formData.phone = "+1" + formData.phone;
    }

    if (!formData.phone || !isValidPhoneNumber(formData.phone)) {
      setErrorMessage(
        "Invalid Number (please use international format, such as +17031234567)",
      );
      return;
    }

    await updatePhone(formData.phone);
  };

  return (
    <MFAContainer>
      <Typography variant="body1" marginTop={2} color="error" fontWeight="bold">
        Your organization requires MFA. Please enroll to login.
      </Typography>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(setupMfa)}
          className={classes.form}
        >
          <CustomTextField
            name="phone"
            label="Phone Number (International format, ex. +17031234567)"
            required
            autoFocus
            errorText="Valid SMS phone number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon
                    className={classes.adornmentIcon}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Grid
            container
            justifyContent="center"
            marginTop={1}
            marginBottom={2}
          >
            <Grid item>
              <SubmitButton margin={{right: 1}}>Submit</SubmitButton>
            </Grid>
            <Grid item>
              <ReloadButton onReload={clearCookieSession}>Cancel</ReloadButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <Typography variant="caption">
        After you submit your number, you will be returned to the previous
        screen to login again with MFA.
      </Typography>
    </MFAContainer>
  );
};

export default MFASetupForm;

import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {CancelButton, SubmitButton} from "../buttons";

const useModalStyle = makeStyles()((theme) => ({
  actions: {
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  dialogPadding: {
    padding: theme.spacing(1),
  },
}));

const ConfirmationModal = ({
  open,
  buttonLabels,
  children,
  fullScreen,
  onAgree,
  onCancel,
  onClose,
  paperStyle,
  reverse,
  title = "Are you sure?",
  fullWidth = false,
  maxWidth = "sm",
  overTour,
}) => {
  const {classes} = useModalStyle();
  const [isLoading, setIsLoading] = useState(false);

  const handleAgree = () => {
    setIsLoading(true);

    Promise.resolve(onAgree()).then(() => {
      setIsLoading(false);
      onClose();
    });
  };

  const handleCancel = () => {
    onCancel && onCancel();
    onClose();
  };

  const dialogStyle = {
    style: {
      ...paperStyle,
      padding: classes.dialogPadding.padding,
    },
  };

  const dialogContent = children && (
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
  );

  const cancelButton = (
    <CancelButton onCancel={handleCancel} variant="modal">
      {(buttonLabels && buttonLabels.no) || "No"}
    </CancelButton>
  );

  const submitButton = (
    <SubmitButton onSubmit={handleAgree} isLoading={isLoading}>
      {(buttonLabels && buttonLabels.yes) || "Yes"}
    </SubmitButton>
  );

  const space = <span style={{marginLeft: 8}} />;

  const dialogActions = (
    <DialogActions className={classes.actions} id="dialog-actions">
      {reverse && (
        <>
          {cancelButton}
          {space}
          {submitButton}
        </>
      )}
      {!reverse && (
        <>
          {submitButton}
          {space}
          {cancelButton}
        </>
      )}
    </DialogActions>
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleCancel}
      aria-labelledby="confirmation-title"
      id="confirmation"
      PaperProps={dialogStyle}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={{zIndex: overTour ? 100000 : 1300}}
    >
      <DialogTitle id="confirmation-title">{title}</DialogTitle>
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  buttonLabels: PropTypes.shape({
    yes: PropTypes.string,
    no: PropTypes.string,
  }),
  children: PropTypes.any,
  fullScreen: PropTypes.bool,
  onAgree: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  paperStyle: PropTypes.object,
  reverse: PropTypes.bool,
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  overTour: PropTypes.bool,
};

export default ConfirmationModal;

export const useConfirmationModal = () => {
  const [open, setOpen] = useState(false);
  const [store, setStore] = useState(null);

  return {
    isOpen: open,
    value: store,
    setValueAndOpen: (value) => {
      setOpen(true);
      setStore(value);
    },
    clearValue: () => setStore(null),
    clearValueAndClose: () => {
      setStore(null);
      setOpen(false);
    },
    setOpen: () => setOpen(true),
    setClosed: () => setOpen(false),
  };
};

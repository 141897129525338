import {gql} from "@apollo/client";

const CORE_ACTION_PHASE_FIELDS = gql`
  fragment CoreActionPhaseFields on ActionPhase {
    id
    name
    description
    ordering
    backgroundColor
    foregroundColor
  }
`;

const GET_ACTION_PHASES = gql`
  ${CORE_ACTION_PHASE_FIELDS}
  query GetActionPhases {
    actionPhases(orderBy: {ordering: asc}) {
      ...CoreActionPhaseFields
    }
  }
`;

const CORE_INCIDENT_PHASE_FIELDS = gql`
  fragment CoreIncidentPhaseFields on IncidentPhase {
    id
    name
    description
    ordering
  }
`;

const GET_INCIDENT_PHASES = gql`
  ${CORE_INCIDENT_PHASE_FIELDS}
  query GetIncidentPhases {
    incidentPhases(orderBy: {ordering: asc}) {
      ...CoreIncidentPhaseFields
    }
  }
`;

const GET_ALL_PHASES = gql`
  ${CORE_ACTION_PHASE_FIELDS}
  ${CORE_INCIDENT_PHASE_FIELDS}
  query GetAllPhases {
    actionPhases(orderBy: {ordering: asc}) {
      ...CoreActionPhaseFields
    }
    incidentPhases(orderBy: {ordering: asc}) {
      ...CoreIncidentPhaseFields
    }
  }
`;

export {GET_ACTION_PHASES, GET_INCIDENT_PHASES, GET_ALL_PHASES};

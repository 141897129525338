import React, {useState} from "react";
import PropTypes from "prop-types";
import {IdleTimerProvider as NativeIdleTimerProvider} from "react-idle-timer";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {userIdleTimeoutInMilliseconds} from "../resources/environment";
import {useAuth} from "../components/authentication/Context/AuthProvider";
import {useAuthStyles} from "../components/authentication/AuthStyles";
import {CancelButton, SubmitButton} from "../components/buttons";

export const IdleTimerProvider = ({children}) => {
  const [isIdle, setIsIdle] = useState(false);
  const auth = useAuth();

  const onIdle = () => {
    setIsIdle(true);
  };

  const handleResume = () => {
    window.location.reload();
  };

  const handleLogout = async () => {
    await auth.logout();
  };

  const UserIdle = () => {
    const {classes} = useAuthStyles();
    return (
      <>
        <Box ml={3} mt={2}>
          <div className={classes.logo} aria-label="BreachRx" />
        </Box>
        <Dialog open aria-labelledby="idle-title" onClose={() => {}}>
          <DialogTitle id="idle-title">Session Timeout</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We&apos;ve paused your session for security reasons due to
              inactivity. Please resume your session or logout below.
            </DialogContentText>
            <DialogActions sx={{justifyContent: "center", marginTop: 1}}>
              <SubmitButton margin={{right: 2}} onSubmit={handleResume}>
                Resume
              </SubmitButton>
              <CancelButton onCancel={handleLogout}>Logout</CancelButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <NativeIdleTimerProvider
      onIdle={onIdle}
      timeout={userIdleTimeoutInMilliseconds}
    >
      {isIdle ?
        <UserIdle /> :
        children}
    </NativeIdleTimerProvider>
  );
};

IdleTimerProvider.propTypes = {
  children: PropTypes.any,
};

import React from "react";
import PropTypes from "prop-types";
import {Grid} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {isNumber} from "underscore";

const useButtonGroupStyles = makeStyles()((theme, {margin}) => ({
  buttonGrid: {
    marginTop:
      isNumber(margin?.top) ? theme.spacing(margin.top) :
      margin !== false ? theme.spacing(0.5) :
      undefined,
  },
  spacer: {
    marginBottom:
      isNumber(margin?.bottom) ? theme.spacing(margin.bottom) :
      margin !== false ? theme.spacing(1) :
      undefined,
  },
}));

const ButtonGroup = ({children, margin}) => {
  const {classes} = useButtonGroupStyles({margin: margin});
  const spacerId =
    margin === false ? "spacer-none" : (
      `spacer-${margin?.top ?? 0.5}-${margin?.bottom ?? 1}`
    );
  return (
    <>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        className={classes.buttonGrid}
      >
        <Grid
          item
          xs
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          {children}
        </Grid>
      </Grid>
      <div id={spacerId} className={classes.spacer} />
    </>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.any,
  margin: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default ButtonGroup;

import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import {Tooltip, Typography, useTheme} from "@mui/material";
import {isString} from "underscore";

const StyledTooltip = ({
  backgroundColor,
  children,
  interactive,
  forwardedRef,
  placement,
  spanProps = {},
  title,
}) => {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";
  const styledTitle =
    isString(title) ?
      <Typography variant="caption">{title}</Typography> :
      title;

  return (
    <Tooltip
      title={styledTitle}
      disableInteractive={!interactive}
      placement={placement}
      enterDelay={350}
      enterNextDelay={350}
      TransitionProps={{
        style: {
          backgroundColor:
            backgroundColor ?? theme.palette.grey[isDarkTheme ? "700" : "800"],
          borderRadius: 0,
        },
      }}
    >
      <span ref={forwardedRef} data-testid="tooltip-wrapper" {...spanProps}>
        {children}
      </span>
    </Tooltip>
  );
};

StyledTooltip.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.any,
  interactive: PropTypes.bool,
  forwardedRef: PropTypes.any,
  placement: PropTypes.string,
  spanProps: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
};

export default forwardRef((props, ref) => (
  <StyledTooltip {...props} forwardedRef={ref} />
));

import {gql} from "@apollo/client";

const CORE_POLICY_FIELDS = gql`
  fragment CorePolicyFields on AccessPolicy {
    id
    name
    action
    subject
    conditions
    subject
    fields
    inverted
  }
`;

const CORE_ROLE_FIELDS = gql`
  fragment CoreAccessPolicyRoleFields on AccessPolicyRole {
    id
    roleId
  }
`;

const GET_POLICY = gql`
  ${CORE_POLICY_FIELDS}
  ${CORE_ROLE_FIELDS}
  query GetPolicy($name: String!) {
    accessPolicies(where: {name: {equals: $name}}) {
      ...CorePolicyFields
      roles {
        ...CoreAccessPolicyRoleFields
      }
    }
  }
`;

const GET_POLICIES_BY_NAMES = gql`
  ${CORE_POLICY_FIELDS}
  ${CORE_ROLE_FIELDS}
  query GetPoliciesByName($names: [String!]!) {
    accessPolicies(where: {name: {in: $names}}) {
      ...CorePolicyFields
      roles {
        ...CoreAccessPolicyRoleFields
      }
    }
  }
`;

const UPDATE_POLICY = gql`
  ${CORE_POLICY_FIELDS}
  ${CORE_ROLE_FIELDS}
  mutation UpdatePolicy(
    $policyId: Int!
    $rolesToCreate: [AccessPolicyRoleCreateManyAccessPolicyInput!]!
    $rolesToDelete: [Int!]
  ) {
    updateOneAccessPolicy(
      where: {id: $policyId}
      data: {
        roles: {
          createMany: {data: $rolesToCreate}
          deleteMany: {roleId: {in: $rolesToDelete}}
        }
      }
    ) {
      ...CorePolicyFields
      roles {
        ...CoreAccessPolicyRoleFields
      }
    }
  }
`;

const GET_POLICIES = gql`
  ${CORE_POLICY_FIELDS}
  ${CORE_ROLE_FIELDS}
  query GetPolicies {
    accessPolicies {
      ...CorePolicyFields
      roles {
        ...CoreAccessPolicyRoleFields
      }
    }
  }
`;

const GET_POLICIES_WITHOUT_ROLES = gql`
  ${CORE_POLICY_FIELDS}
  query GetPoliciesWithoutRoles {
    accessPolicies(where: {roles: {none: {}}}) {
      ...CorePolicyFields
    }
  }
`;

export {
  GET_POLICY,
  GET_POLICIES,
  GET_POLICIES_BY_NAMES,
  GET_POLICIES_WITHOUT_ROLES,
  UPDATE_POLICY,
};

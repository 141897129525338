import React from "react";
import PropTypes from "prop-types";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";
import styles from "../../resources/scss/App.scss";

const EmptySpinningHourglass = ({
  color = "primary",
  id = "empty-spinning-hourglass",
  fontSize,
  sx,
}) => (
  <HourglassEmpty
    color={color}
    className={styles.spin}
    id={id}
    fontSize={fontSize}
    sx={sx}
  />
);

EmptySpinningHourglass.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string,
  fontSize: PropTypes.string,
  sx: PropTypes.object,
};

export default EmptySpinningHourglass;

import {base64StringToBlob} from "blob-util";
import {isString} from "underscore";

const toBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const fromBase64 = async (stream) => {
  const blob = base64StringToBlob(stream, "application/octet-stream");
  const text = await blob.text();
  return text;
};

const toBase64URL = (text) => {
  if (!isString(text)) return "";
  const base64Text = Buffer.from(text).toString("base64");
  return base64Text.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

const fromBase64URL = (text) => {
  if (!isString(text)) return "";
  const base64Text = text.replace(/-/g, "+").replace(/_/g, "/");
  const paddedBase64Text = base64Text.padEnd(
    base64Text.length + ((4 - (base64Text.length % 4)) % 4),
    "=",
  );
  return Buffer.from(paddedBase64Text, "base64").toString();
};

export {toBase64, fromBase64, toBase64URL, fromBase64URL};

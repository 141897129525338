import {makeStyles} from "tss-react/mui";

export const useTopbarStyles = makeStyles()((theme) => ({
  grow: {
    flexGrow: 1,
  },
  rightMenu: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `${theme.palette.layout.topbar.boxShadow} !important`,
    zIndex: theme.zIndex.drawer + 1,
    "@media print": {
      boxShadow: "none !important",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  toolbarGroup: {
    display: "flex",
    alignItems: "center",
  },
  menuButton: {
    color: theme.palette.layout.icon.color,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0),
      transform: "scale(0.9)",
    },
    "&:hover": {
      backgroundColor: "unset",
    },
    "@media print": {
      display: "none",
    },
  },
  topbarButton: {
    color: theme.palette.layout.icon.color,
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.9)",
    },
    "&:hover": {
      fill: theme.palette.primary.main,
    },
    "@media print": {
      display: "none",
    },
  },
  buttonText: {
    paddingTop: ".25em",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
    },
  },
  gettingStartedPaper: {
    backgroundColor: theme.palette.draggable.accent,
    borderRadius: 0,
  },
  startedContainer: {
    maxHeight: theme.spacing(75),
    width: theme.spacing(55),
    display: "flex",
    flexDirection: "column",
    borderRadius: 0,
  },
  gettingStartedButton: {
    display: "flex",
    flexDirection: "column",
    margin: "0 0 1px 0",
    "@media print": {
      display: "none",
    },
  },
  startedTitle: {
    fontWeight: "lighter",
    fontSize: "1.1rem",
  },
  gettingStartedText: {
    padding: 0,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
    },
  },
  startedListWrapper: {
    flexGrow: 1,
    overflow: "auto",
    scrollbarWidth: "thin",
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(1),
  },
  startedItemButton: {
    paddingRight: `${theme.spacing(1)} !important`,
    paddingLeft: theme.spacing(1),
  },
  incompleteItem: {
    fontSize: "0.9rem",
  },
  completedItem: {
    fontSize: "0.9rem",
    textDecoration: "line-through",
    color: "grey",
  },
  completedItemCaption: {
    textDecoration: "line-through",
    color: "grey",
  },
  progressBar: {
    height: "3px",
  },
  topbarButtonMenu: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      "& button": {
        padding: theme.spacing(0),
        marginRight: theme.spacing(0.5),
      },
    },
    "@media print": {
      display: "none",
    },
  },
  topbarIncidentButtonGroup: {
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.9)",
      padding: theme.spacing(0.5),
      minWidth: theme.spacing(6),
    },
    "@media print": {
      display: "none",
    },
  },
  topbarIncidentButton: {
    borderRight: "none",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      "& span": {
        color: theme.palette.primary.contrastText,
        "& svg": {
          fill: theme.palette.primary.contrastText,
        },
      },
    },
    "@media print": {
      display: "none",
    },
  },
  topbarIconButton: {
    "&:hover": {
      backgroundColor: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.9)",
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(-1),
    },
    "@media print": {
      display: "none",
    },
  },
  topbarSpace: {
    marginRight: theme.spacing(2),
    minHeight: "34.25px",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  topbarHideIfMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  accountIcon: {
    color: theme.palette.layout.icon.color,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(-3),
    },
    "@media print": {
      display: "none",
    },
  },
  avatar: {
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(-6),
      fontSize: "14px !important",
      transform: "scale(0.8)",
    },
  },
  topbarLogo: {
    backgroundImage: `url(${theme.palette.logo})`,
    height: 43,
    width: 192,
    margin: "auto 0",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.7)",
      marginLeft: theme.spacing(-4),
      padding: theme.spacing(0),
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

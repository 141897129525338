import React from "react";
import propTypes from "prop-types";
import {Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import LogRocket from "logrocket";

const useErrorStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    borderColor: theme.palette.error.main,
    margin: theme.spacing(1),
  },
  buttons: {
    paddingTop: theme.spacing(0),
  },
  roboto: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "normal",
  },
  spacedText: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    width: "100%",
  },
  warningIcon: {
    color: theme.palette.error.main,
    fontSize: "100px",
    width: "100%",
  },
}));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    LogRocket.captureException(error);

    if (process.env.NODE_ENV !== "production") {
      console.error(error.toString(), errorInfo.componentStack);
    }
  }

  render() {
    const {error} = this.state;
    const dashboardPath = "/dashboard";

    const ErrorBoundaryComponent = () => {
      const {classes} = useErrorStyles();
      return (
        <div className={classes.root}>
          <ReportProblemOutlinedIcon
            color="error"
            align="center"
            className={classes.warningIcon}
          />
          <Typography
            variant="h4"
            align="center"
            className={classes.spacedText}
          >
            It looks like something went wrong.
          </Typography>
          <Typography
            variant="h6"
            align="center"
            className={`${classes.roboto} ${classes.spacedText}`}
          >
            Please try again in a few minutes or reload the platform now.
          </Typography>
          <center className={classes.buttons}>
            <Button
              aria-label="retry"
              className={classes.button}
              variant="outlined"
              color="grey"
              onClick={() => {
                window.location.reload();
              }}
            >
              Try again
            </Button>
            <Button
              aria-label="reload"
              className={classes.button}
              variant="outlined"
              color="grey"
              onClick={() => {
                window.location.href = dashboardPath;
              }}
            >
              Reload
            </Button>
          </center>
        </div>
      );
    };

    if (error) return <ErrorBoundaryComponent />;
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: propTypes.any.isRequired,
  classes: propTypes.object,
};

export default ErrorBoundary;

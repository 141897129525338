import React, {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Integrations from "./Integrations.jsx";

const GoogleIntegration = lazy(
  () => import("./IntegrationViews/GoogleIntegration"),
);
const JiraIntegration = lazy(
  () => import("./IntegrationViews/JiraIntegration"),
);
const MSEntraIntegration = lazy(
  () => import("./IntegrationViews/MSEntraIntegration"),
);
const MSTeamsIntegration = lazy(
  () => import("./IntegrationViews/MSTeamsIntegration"),
);
const OktaIntegration = lazy(
  () => import("./IntegrationViews/OktaIntegration"),
);
const OneLoginIntegration = lazy(
  () => import("./IntegrationViews/OneLoginIntegration"),
);
const OpsgenieIntegration = lazy(
  () => import("./IntegrationViews/OpsgenieIntegration"),
);
const PagerDutyIntegration = lazy(
  () => import("./IntegrationViews/PagerDutyIntegration"),
);
const PingIntegration = lazy(
  () => import("./IntegrationViews/PingIntegration"),
);
const XsoarIntegration = lazy(
  () => import("./IntegrationViews/PaloAltoCortexXSOARIntegration"),
);
const RevelstokeIntegration = lazy(
  () => import("./IntegrationViews/RevelstokeIntegration"),
);
const SalesforceIntegration = lazy(
  () => import("./IntegrationViews/SalesforceIntegration"),
);
const SlackIntegration = lazy(
  () => import("./IntegrationViews/SlackIntegration"),
);
const VantaIntegration = lazy(
  () => import("./IntegrationViews/VantaIntegration"),
);
const ZapierIntegration = lazy(
  () => import("./IntegrationViews/ZapierIntegration"),
);
const ZoomIntegration = lazy(
  () => import("./IntegrationViews/ZoomIntegration"),
);

const IntegrationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Integrations />} />
      <Route path="google" element={<GoogleIntegration />} />
      <Route path="jira" element={<JiraIntegration />} />
      <Route path="msentra" element={<MSEntraIntegration />} />
      <Route path="msteams" element={<MSTeamsIntegration />} />
      <Route path="okta" element={<OktaIntegration />} />
      <Route path="onelogin" element={<OneLoginIntegration />} />
      <Route path="opsgenie" element={<OpsgenieIntegration />} />
      <Route path="pagerduty" element={<PagerDutyIntegration />} />
      <Route path="ping" element={<PingIntegration />} />
      <Route path="xsoar" element={<XsoarIntegration />} />
      <Route path="revelstoke" element={<RevelstokeIntegration />} />
      <Route path="salesforce" element={<SalesforceIntegration />} />
      <Route path="slack" element={<SlackIntegration />} />
      <Route path="vanta" element={<VantaIntegration />} />
      <Route path="zapier" element={<ZapierIntegration />} />
      <Route path="zoom" element={<ZoomIntegration />} />
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default IntegrationRoutes;

import React, {useEffect, useContext, createContext, useState} from "react";
import PropTypes from "prop-types";
import {useQuery} from "@apollo/client";
import {GET_ORGANIZATION_SETTINGS} from "../graphql/organizationQueries";
import {GET_POLICIES_BY_NAMES} from "../graphql/policyQueries";
import {LIST_ROLES} from "../graphql/roleQueries";
import {Loader} from "../components";
import {
  processExactKeyValuePairSettings,
  processTrueFalseSettings,
} from "../resources/orgSettings";
import {each, filter, findWhere, isEmpty, keys, map} from "underscore";

export const OrgSettingsContext = createContext(null);

export const OrgSettingsProvider = ({children}) => {
  const [orgSettings, setSettings] = useState({});

  const {data: settings, loading: settingsLoading} = useQuery(
    GET_ORGANIZATION_SETTINGS,
  );

  const ORG_SETTINGS_POLICIES = {names: ["incident_delete", "action_delete"]};
  const {data: policiesData} = useQuery(GET_POLICIES_BY_NAMES, {
    variables: ORG_SETTINGS_POLICIES,
  });
  const {data: rolesData} = useQuery(LIST_ROLES);

  const incidentDeleteAccessPolicy = findWhere(policiesData?.accessPolicies, {
    name: "incident_delete",
  });
  const actionDeleteAccessPolicy = findWhere(policiesData?.accessPolicies, {
    name: "action_delete",
  });
  const orgDeletePolicies = {
    action_delete: actionDeleteAccessPolicy,
    incident_delete: incidentDeleteAccessPolicy,
  };

  const getPolicyDeleteRoles = (policies) => {
    return map(policies?.roles, (policyRole) =>
      findWhere(rolesData?.roles, {id: policyRole.roleId}),
    );
  };

  const getCurrentRoles = (setting) => {
    if (setting.value) {
      const parsedRoleIds = JSON.parse(setting.value);
      const mappedRoles = map(parsedRoleIds, (parsedRoleId) =>
        findWhere(rolesData.roles, {id: parsedRoleId}),
      );

      return filter(mappedRoles, (mappedRole) => mappedRole !== undefined);
    }
    return [];
  };

  useEffect(() => {
    if (
      settings?.organizationSettings &&
      rolesData &&
      incidentDeleteAccessPolicy &&
      actionDeleteAccessPolicy
    ) {
      const timeframe = findWhere(settings.organizationSettings, {
        key: "complianceTimeframe",
      });
      const durationObject = JSON.parse(timeframe.value);
      const newUserRoleIdsSetting = findWhere(settings.organizationSettings, {
        key: "newUserRoleIds",
      });
      const responseWorkflowName = findWhere(settings.organizationSettings, {
        key: "responseWorkflowName",
      });
      const workflowName = JSON.parse(responseWorkflowName.value);

      const aiPermissionsSetting = findWhere(settings.organizationSettings, {
        key: "aiPermissions",
      });
      const aiPermissions = JSON.parse(aiPermissionsSetting.value);
      each(keys(aiPermissions), (aiPermissionKey) => {
        aiPermissions[aiPermissionKey] =
          aiPermissions[aiPermissionKey] === true ||
          aiPermissions[aiPermissionKey] === "true";
      });

      const keyValuePairSettings = processExactKeyValuePairSettings(
        settings.organizationSettings,
        [
          "actionVisibility",
          "complianceCommunicationChannels",
          "conversationsLegalDisclaimer",
          "notificationSignature",
          "orgFullname",
        ],
      );
      const trueFalseSettings = processTrueFalseSettings(
        settings.organizationSettings,
        [
          "MFARequired",
          "newIncidentsRestricted",
          "attributeTabOnActions",
          "moreActivitiesOnEvents",
          "editActivitiesAllowed",
        ],
      );

      const orgSettings = {
        ...keyValuePairSettings,
        ...trueFalseSettings,
        complianceTimeframeSetting: timeframe,
        [timeframe.key]: durationObject.months + " months",
        newUserRoleIds: getCurrentRoles(newUserRoleIdsSetting),
        newUserRoleIdsSetting,
        policyDeleteRoles: {
          incident_delete: getPolicyDeleteRoles(incidentDeleteAccessPolicy),
          action_delete: getPolicyDeleteRoles(actionDeleteAccessPolicy),
        },
        [responseWorkflowName.key]: {
          upper: workflowName.workflowName,
          lower: workflowName.workflowNameLower,
        },
        responseWorkflowNameSetting: responseWorkflowName,
        aiPermissionsSetting,
        aiPermissions,
      };

      setSettings(orgSettings);
    }
  }, [settings, rolesData, policiesData, orgDeletePolicies?.incident_delete]);

  const providerValues = {
    ...orgSettings,
    getCurrentRoles,
    orgDeletePolicies,
    ORG_SETTINGS_POLICIES,
    rolesData,
    settingsLoading,
  };

  if (isEmpty(orgSettings)) return <Loader fullViewingHeight />;

  return (
    <OrgSettingsContext.Provider value={providerValues}>
      {children}
    </OrgSettingsContext.Provider>
  );
};

export const useOrgSettings = () => {
  const context = useContext(OrgSettingsContext);
  if (!context) {
    throw new Error("Context must be used within the correct provider");
  }
  return context;
};

OrgSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

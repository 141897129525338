@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin animate($animation, $duration, $times) {
  animation: $animation $duration $times;
}

.spin {
  @include keyframes(spinRotation) {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @include animate(spinRotation, 1s, infinite);
}

.blink {
  @include keyframes(blinkAnimation) {
    from,
    to {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  }
  @include animate(blinkAnimation, 1s ease, infinite);
}

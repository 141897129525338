import {gql} from "@apollo/client";

const CORE_MESSAGE_FIELDS = gql`
  fragment CoreMessageFields on Message {
    id
    subject
    message
    isDeleted
    createdAt
    fromId
    from {
      id
      email
      fullName
      profileDocumentPreview
    }
    to {
      id
      user {
        id
        email
        fullName
        profileDocumentPreview
      }
    }
  }
`;

export const GET_UNREAD_MESSAGE_COUNT = gql`
  query GetUnreadMessages($userId: Int!) {
    userMessages(
      where: {
        isDeleted: {equals: false}
        isRead: {equals: false}
        userId: {equals: $userId}
      }
    ) {
      id
    }
  }
`;

export const GET_TO_MESSAGES = gql`
  ${CORE_MESSAGE_FIELDS}
  query GetUserMessages($userId: Int!) {
    userMessages(
      where: {isDeleted: {equals: false}, user: {is: {id: {equals: $userId}}}}
      orderBy: {id: desc}
    ) {
      id
      isDeleted
      isRead
      userId
      messageId
      message {
        ...CoreMessageFields
      }
    }
  }
`;

export const GET_FROM_MESSAGES = gql`
  ${CORE_MESSAGE_FIELDS}
  query GetUserFromMessages($userId: Int!) {
    messages(where: {fromId: {equals: $userId}}, orderBy: {id: desc}) {
      ...CoreMessageFields
    }
  }
`;

export const GET_USER_MESSAGE = gql`
  ${CORE_MESSAGE_FIELDS}
  query GetFromMessage($userId: Int!, $messageID: Int!) {
    user(where: {id: $userId}) {
      id
      fromMessages(where: {id: {equals: $messageID}}) {
        ...CoreMessageFields
      }
      toMessages(where: {messageId: {equals: $messageID}}) {
        id
        isDeleted
        isRead
        messageId
        message {
          ...CoreMessageFields
        }
      }
    }
  }
`;

export const CREATE_MESSAGE = gql`
  ${CORE_MESSAGE_FIELDS}
  mutation CreateMessage(
    $subject: String!
    $message: String!
    $toUsers: [UserMessageCreateManyMessageInput!]!
    $from: Int
  ) {
    createOneMessage(
      data: {
        subject: $subject
        message: $message
        to: {createMany: {data: $toUsers}}
        from: {connect: {id: $from}}
      }
    ) {
      ...CoreMessageFields
    }
  }
`;

export const SET_MESSAGE_READ = gql`
  mutation SetMessageRead($messageID: Int!, $userId: Int!) {
    updateManyUserMessage(
      where: {userId: {equals: $userId}, messageId: {equals: $messageID}}
      data: {isRead: {set: true}}
    ) {
      count
    }
  }
`;

export const DELETE_USER_MESSAGE = gql`
  mutation DeleteUserMessage($messageID: Int!, $userId: Int!) {
    deleteManyUserMessage(
      where: {userId: {equals: $userId}, messageId: {equals: $messageID}}
    ) {
      count
    }
  }
`;

export const DELETE_SENT_MESSAGE = gql`
  mutation DeleteSentMessage($messageID: Int!, $userId: Int!) {
    deleteManyMessage(
      where: {from: {is: {id: {equals: $userId}}}, id: {equals: $messageID}}
    ) {
      count
    }
  }
`;

export const SUBSCRIBE_TO_USER_MESSAGES = gql`
  ${CORE_MESSAGE_FIELDS}
  subscription SubscribeToUserMessages($userId: Int!) {
    subscribeToUserMessages(userId: $userId) {
      isRead
      message {
        ...CoreMessageFields
      }
    }
  }
`;

import React from "react";
import PropTypes from "prop-types";
import {Divider} from "@mui/material";
import FilterButton from "./FilterButton";
import {useFilterButtonStyles} from "./buttonStyles";
import {isEmpty, isUndefined, map} from "underscore";

const FilterButtonGroup = ({selections, selectedView, setSelection}) => {
  if (
    isEmpty(selections) ||
    isEmpty(selectedView) ||
    isUndefined(setSelection)
  ) {
    return null;
  }
  const {classes} = useFilterButtonStyles();

  return (
    <div className={classes.filterButtonGroup}>
      {map(selections, (selection, index) => (
        <span key={index}>
          {["Archived", "All"].includes(selection) ?
            <Divider
              orientation="vertical"
              className={classes.filterGroupDivider}
            /> :
            null}
          <FilterButton
            isSelected={selectedView === selection}
            onClick={() => setSelection(selection)}
            id={selection}
          >
            {selection}
          </FilterButton>
        </span>
      ))}
    </div>
  );
};

FilterButtonGroup.propTypes = {
  selections: PropTypes.array,
  selectedView: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired,
};

export default FilterButtonGroup;

import React, {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CustomTextField from "../forms/CustomTextField";
import CustomSwitch from "../forms/CustomSwitch";
import {tagline} from "../../resources/environment";
import {useAuthStyles} from "./AuthStyles";
import {SubmitButton} from "../buttons";
import {loginSchema} from "./schemas";
import {useAuth} from "./Context/AuthProvider";
import ForgotPasswordLink from "./ForgotPasswordLink";
import {ProviderLogins} from "./ProviderLogins";
import Loader from "../Loader";
import {isEmpty, isString} from "underscore";

export const LoginForm = () => {
  const {classes} = useAuthStyles();
  const {
    login,
    errorMessage,
    setErrorMessage,
    isAuthLoading,
    isAltAuthLoading,
  } = useAuth();
  const formMethods = useForm({resolver: yupResolver(loginSchema)});

  useEffect(() => {
    const messageToDisplay = window.sessionStorage.getItem("loginMessage");
    if (isString(messageToDisplay) && !isEmpty(messageToDisplay)) {
      setErrorMessage(messageToDisplay);
      window.sessionStorage.removeItem("loginMessage");
    }
  }, []);

  if (isAltAuthLoading) return <Loader fullViewingHeight />;

  return (
    <FormProvider {...formMethods}>
      <Container className={classes.loginContainer}>
        <center>
          <Paper elevation={10} square className={classes.login}>
            <Grid container className={classes.centered}>
              <Grid item xs={12}>
                <Button
                  disabled
                  className={classes.logo}
                  aria-label="BreachRx"
                />
                <Typography
                  id="tagline"
                  variant="h5"
                  className={classes.tagline}
                >
                  {tagline}
                </Typography>
              </Grid>
            </Grid>
            <ProviderLogins />
            <form
              onSubmit={formMethods.handleSubmit(login)}
              className={classes.form}
            >
              <CustomTextField
                name="username"
                label="Email"
                required
                errorText="Valid email address is required"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon
                        className={classes.adornmentIcon}
                      />
                    </InputAdornment>
                  ),
                }}
                customClasses={classes.noAutofill}
              />
              <CustomTextField
                name="password"
                label="Password"
                type="password"
                required
                errorText="Password is required"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon className={classes.adornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              <Grid>
                <CustomSwitch
                  name="rememberMe"
                  label="Remember Me"
                  defaultChecked={false}
                />
              </Grid>
              <ForgotPasswordLink />
              <SubmitButton margin={{top: 2}} inline isLoading={isAuthLoading}>
                Sign In
              </SubmitButton>
            </form>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className={classes.error}
            >
              {errorMessage}
            </Typography>
          </Paper>
        </center>
      </Container>
    </FormProvider>
  );
};

import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {ListItemButton, ListItemText} from "@mui/material";
import StyledBadge from "../badges/StyledBadge";
import {Can} from "../../context/AuthzProvider";
import {useSidebar} from "./Context/SidebarProvider";
import UpgradeAdornment from "../UpgradeAdornment";
import SidebarIcon from "./SidebarIcon";
import {useSidebarItemStyles} from "./sidebarStyles";

const SidebarEntry = ({
  to,
  text,
  icon,
  hide,
  badge,
  needUpgrade,
  relatedModelName,
}) => {
  if (hide) return null;

  const {currentPage, setCurrentPage, isSidebarHidden} = useSidebar();
  const isCurrentPage = to.includes(currentPage);
  const {classes} = useSidebarItemStyles({isSidebarHidden, isCurrentPage});

  return (
    <Can I="update" a={relatedModelName}>
      <ListItemButton
        component={!needUpgrade ? NavLink : undefined}
        to={to}
        id={`sidebar-${text}`}
        onClick={() => setCurrentPage(to)}
        className={classes.item}
        disableRipple
      >
        <StyledBadge badgeContent={!needUpgrade && isSidebarHidden && badge}>
          <SidebarIcon icon={icon} text={text} />
        </StyledBadge>
        <StyledBadge
          badgeContent={!needUpgrade && !isSidebarHidden && badge}
          max={999}
          padParent
        >
          <ListItemText
            primary={text}
            className={classes.itemText}
            primaryTypographyProps={{variant: "body2"}}
          />
        </StyledBadge>
        {needUpgrade && <UpgradeAdornment fontSize="small" />}
      </ListItemButton>
    </Can>
  );
};

SidebarEntry.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  hide: PropTypes.bool,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  needUpgrade: PropTypes.bool,
  relatedModelName: PropTypes.string,
};

export default SidebarEntry;

import {makeStyles} from "tss-react/mui";
import {sidebarWidth, sidebarClass} from "../../resources/environment";

export const useSidebarStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  overviewPaper: {
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: 0,
    padding: 0,
    height: theme.palette.layout.overview.height,
    width: sidebarWidth.open,
    [`&.${sidebarClass}`]: {
      width: sidebarWidth.collapsed,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    boxShadow: `${theme.palette.layout.overview.boxShadow} !important`,
    "@media print": {
      display: "none",
    },
  },
  drawer: {
    width: sidebarWidth.open,
    flexShrink: 0,
    [`&.${sidebarClass}`]: {
      width: sidebarWidth.collapsed,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "@media print": {
      display: "none",
    },
  },
  drawerPaper: {
    width: "inherit",
    maxHeight: `calc(100% - ${theme.palette.layout.sidebar.overviewOffset})`,
    borderRight: "none",
    boxShadow: `${theme.palette.layout.sidebar.boxShadow} !important`,
    scrollbarWidth: "thin",
    scrollbarColor: `${theme.palette.layout.scrollbar.thumb} ${theme.palette.layout.scrollbar.track}`,
    "&::-webkit-scrollbar": {
      width: "0.4rem",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.layout.scrollbar.thumb,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.layout.scrollbar.track,
    },
  },
  drawerContainer: {
    overflow: "auto",
  },
}));

export const useSidebarContentStyles = makeStyles()((theme) => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  container: {
    backgroundColor: theme.palette?.draggable?.accent,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: "default",
  },
}));

export const useSidebarItemStyles = makeStyles()(
  (theme, {isSidebarHidden, isCurrentPage}) => ({
    menuList: {
      "& a .MuiListItemIcon-root": {
        marginLeft: isSidebarHidden ? 0 : theme.spacing(2),
      },
    },
    item: {
      justifyContent: isSidebarHidden ? "center" : undefined,
      paddingBottom: theme.spacing(0.25),
      borderRight:
        isCurrentPage ? `3px solid ${theme.palette.primary.main}` : undefined,
      background:
        isCurrentPage && theme.palette.mode !== "dark" ?
          theme.palette.primary.megaLight :
          undefined,
      "&:hover": {
        background: theme.palette.primary.main,
        backgroundColor: `${theme.palette.primary.main} !important`,
        "& div p": {color: theme.palette.background.default},
        "& div svg, svg": {fill: theme.palette.background.default},
      },
    },
    itemText: {
      display: isSidebarHidden ? "none" : undefined,
    },
  }),
);

export const useSidebarIconStyles = makeStyles()(
  (theme, {isSidebarHidden}) => ({
    listItemIcon: {
      color: theme?.palette?.layout?.icon?.color,
      justifyContent: isSidebarHidden ? "center" : undefined,
      minWidth: theme.spacing(5),
    },
  }),
);

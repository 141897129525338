import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import {ALERT} from "../../resources/environment";
import {ButtonText} from "../ButtonText";
import {useSubmitStyles} from "./buttonStyles";

const SubmitButton = ({
  children = "Submit",
  disabled,
  isLoading,
  margin,
  onSubmit,
  id,
  inline = false,
  isSubmit = true,
  styleHover = true,
  variant = "outlined",
}) => {
  const [loading, setLoading] = useState(false);
  const {classes} = useSubmitStyles({inline, margin, styleHover});

  const onClick = async () => {
    setLoading(true);
    try {
      onSubmit && (await onSubmit());
    } catch (e) {
      ALERT.error(e.message || JSON.stringify(e));
      console.error(e);
    }
    setLoading(false);
  };

  const currentlyLoading = loading || isLoading;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          type={isSubmit ? "submit" : "button"}
          id={id || "submit"}
          color="primary"
          variant={variant}
          onClick={onClick}
          className={classes.submit}
          disabled={currentlyLoading || disabled}
        >
          <ButtonText loading={currentlyLoading} text={children} />
        </Button>
      </div>
    </div>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  margin: PropTypes.object,
  onSubmit: PropTypes.func,
  id: PropTypes.any,
  inline: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmit: PropTypes.bool,
  styleHover: PropTypes.bool,
  variant: PropTypes.string,
};

export default SubmitButton;

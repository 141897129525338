import React from "react";
import PropTypes from "prop-types";
import {Button, Container, Grid, Paper, Typography} from "@mui/material";
import {tagline} from "../../../resources/environment";
import {useAuthStyles} from "../AuthStyles";
import {useAuth} from "../Context/AuthProvider";
import {isObject, isString} from "underscore";

const MFAContainer = ({children}) => {
  const {classes} = useAuthStyles();
  const {errorMessage} = useAuth();

  const errorToDisplay =
    isObject(errorMessage) ? errorMessage.message :
    isString(errorMessage) ? errorMessage :
    "";

  return (
    <Container className={classes.loginContainer}>
      <center>
        <Paper elevation={10} square className={classes.MFA}>
          <Grid container className={classes.centered}>
            <Grid item xs={12}>
              <Button
                disabled
                className={classes.logo}
                aria-label="BreachRx"
                alt="BreachRx Logo"
              />
              <Typography variant="h5" className={classes.tagline}>
                {tagline}
              </Typography>
              <Typography variant="h5" className={classes.subheading}>
                Sign In
              </Typography>
            </Grid>
          </Grid>
          {children}
          <Typography variant="caption" display="block" gutterBottom>
            {errorToDisplay}
          </Typography>
        </Paper>
      </center>
    </Container>
  );
};

MFAContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MFAContainer;

import React, {useState, useContext, createContext} from "react";
import PropTypes from "prop-types";
import {initializeTheme, toggleTheme} from "../resources/theme";

export const ThemeWrapperContext = createContext(null);

export const ThemeWrapperProvider = ({children}) => {
  const [activeTheme, setActiveTheme] = useState(initializeTheme());

  const toggleActiveTheme = () => toggleTheme({activeTheme, setActiveTheme});

  return (
    <ThemeWrapperContext.Provider
      value={{activeTheme, setActiveTheme, toggleActiveTheme}}
    >
      {children}
    </ThemeWrapperContext.Provider>
  );
};

ThemeWrapperProvider.propTypes = {
  children: PropTypes.any,
};

export const useTheme = () => {
  return useContext(ThemeWrapperContext);
};

import React from "react";
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import axios from "axios";
import CustomTextField from "../forms/CustomTextField";
import {tagline, recoveryCodeMessage} from "../../resources/environment";
import {SubmitButton} from "../buttons";
import {useAuthStyles} from "./AuthStyles";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {forgotPasswordSchema} from "./schemas";
import NewPasswordFields from "./NewPasswordFields";
import {useAuth} from "./Context/AuthProvider";
import {clearCookieSession} from "../../resources/cookies";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const ForgotPasswordForm = () => {
  const {classes} = useAuthStyles();
  const {
    errorMessage,
    setErrorMessage,
    isAuthLoading,
    setIsAuthLoading,
    orgName,
  } = useAuth();
  const forgotPasswordCode = new URLSearchParams(window.location.search).get(
    "confirmationCode",
  );
  const username = new URLSearchParams(window.location.search).get("username");

  const formMethods = useForm({
    defaultValues: {forgotPasswordCode, username},
    resolver: yupResolver(forgotPasswordSchema),
  });

  const confirmForgotPassword = async (data) => {
    setIsAuthLoading(true);
    if (data.newPassword !== data.confirmNewPassword) {
      setErrorMessage("Passwords do not match");
      setIsAuthLoading(false);
      return;
    }

    try {
      await axios.post(`${AUTH_ENDPOINT}/password/forgot`, {
        orgName,
        Username: data.username,
        ConfirmationCode: data.forgotPasswordCode,
        Password: data.newPassword,
      });
      window.sessionStorage.setItem(
        "loginMessage",
        "Password successfully updated. Please log in.",
      );
      window.location.href = "/";
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ??
          error?.message ??
          JSON.stringify(error),
      );
    } finally {
      setIsAuthLoading(false);
    }
  };

  const handleCancel = () => {
    clearCookieSession();
    window.location.href = "/";
  };

  return (
    <FormProvider {...formMethods}>
      <Container className={classes.loginContainer}>
        <center>
          <Paper elevation={10} className={classes.newPassword}>
            <Grid container className={classes.centered}>
              <Grid item xs={12}>
                <Button
                  disabled
                  className={classes.logo}
                  aria-label="BreachRx"
                  alt="BreachRx Logo"
                />
                <Typography variant="h5" className={classes.tagline}>
                  {tagline}
                </Typography>
                <br />
                <Typography variant="caption" className={classes.content}>
                  {recoveryCodeMessage}
                </Typography>
              </Grid>
            </Grid>
            <form
              onSubmit={formMethods.handleSubmit(confirmForgotPassword)}
              className={classes.form}
            >
              <CustomTextField
                name="username"
                label="Email"
                type="text"
                required
                errorText="A valid email is required"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon
                        className={classes.adornmentIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <CustomTextField
                name="forgotPasswordCode"
                label="Recovery code"
                type="text"
                required
                errorText="A valid recovery code is required"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon
                        className={classes.adornmentIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <NewPasswordFields />
              <Grid container justifyContent="center">
                <Grid item>
                  <SubmitButton
                    inline
                    margin={{top: 1}}
                    isLoading={isAuthLoading}
                  >
                    Submit
                  </SubmitButton>
                </Grid>
                <Grid item sx={{marginTop: 1, marginLeft: 1.5}}>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Typography variant="caption" display="block" gutterBottom>
              {errorMessage ?? ""}
            </Typography>
          </Paper>
        </center>
      </Container>
    </FormProvider>
  );
};

export default ForgotPasswordForm;

import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Close} from "@mui/icons-material";

const useStyledDialogStyles = makeStyles()((theme) => ({
  dialogPaper: {
    padding: theme.spacing(1),
  },
  floatActions: {
    float: "right",
  },
  usePointer: {
    cursor: "pointer",
  },
}));

const StyledDialog = ({
  children,
  isOpen,
  modalActions,
  onClose,
  title,
  disableBackdropClick = true,
}) => {
  if (!isOpen) return null;
  const {classes} = useStyledDialogStyles();

  const handleClose = (event, reason) => {
    if (!disableBackdropClick || reason !== "backdropClick") {
      onClose && onClose();
    }
  };

  const Title = () => (
    <Grid item xs={4}>
      <Typography variant="h6" id="dialog-title">
        {title}
      </Typography>
    </Grid>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={disableBackdropClick}
      fullWidth
      maxWidth="md"
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <DialogActions>
        <Grid container>
          <Title />
          <Grid item xs={8}>
            <span className={classes.floatActions} id="dialog-actions">
              {modalActions}
            </span>
          </Grid>
        </Grid>
        <Close
          onClick={handleClose}
          id="dialog-close"
          className={classes.usePointer}
        />
      </DialogActions>
      <DialogContent id="dialog-content">{children}</DialogContent>
    </Dialog>
  );
};

StyledDialog.propTypes = {
  children: PropTypes.element,
  isOpen: PropTypes.bool,
  modalActions: PropTypes.element,
  onClose: PropTypes.func,
  title: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
};

export default StyledDialog;

import React, {lazy, Suspense} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useAuthz} from "./context/AuthzProvider";
import {useUser} from "./components/user/Context/UserProvider";
import {useOrgSettings} from "./context/OrgSettingsProvider";
import IntegrationRoutes from "./pages/Integrations";

const AsyncLandingPage = lazy(() => import("./pages/LandingPage/LandingPage"));
const AsyncLandingPageNew = lazy(
  () => import("./pages/LandingPage/LandingPageNew"),
);
const AsyncMyActionsPage = lazy(() => import("./pages/MyActions"));
const AsyncIncidentRoutes = lazy(() => import("./pages/Incidents"));
const AsyncObligationRoutes = lazy(() => import("./pages/Obligations"));
const AsyncTaskRoutes = lazy(() => import("./pages/Tasks"));
const AsyncPlaybookRoutes = lazy(() => import("./pages/Playbooks"));
const AsyncPlayground = lazy(() => import("./pages/Playground/Playground"));
const AsyncReports = lazy(() => import("./pages/Reports"));
const AsyncMessaging = lazy(() => import("./pages/Messaging"));
const AsyncNews = lazy(() => import("./pages/News/News"));
const AsyncSchemaRoutes = lazy(() => import("./pages/Schema"));
const AsyncUserRoutes = lazy(() => import("./pages/Users"));
const AsyncRoleRoutes = lazy(() => import("./pages/Roles"));
const AsyncOrganization = lazy(() => import("./pages/Organization"));
const AsyncNotifications = lazy(() => import("./pages/Notifications"));
const AsyncDatasets = lazy(() => import("./pages/Datasets"));
const AsyncApiKeys = lazy(() => import("./pages/ApiKeys"));
const AsyncUserProfile = lazy(() => import("./pages/UserProfile/UserProfile"));
const AsyncComplianceRoutes = lazy(() => import("./pages/Compliance"));
const AsyncEvidenceRoutes = lazy(() => import("./pages/Evidence"));

const PlatformRoutes = () => {
  const ability = useAuthz();
  const {user} = useUser();
  const {responseWorkflowName} = useOrgSettings();
  const isAdmin = user?.hasAdminRole;

  const canEditSchema =
    ability.can("update", "ObligationType") ||
    ability.can("update", "IncidentType") ||
    ability.can("update", "TaskType") ||
    ability.can("update", "IncidentSeverity") ||
    ability.can("update", "IncidentPhase") ||
    ability.can("update", "ActionPhase");

  const storedRedirectPath = window.sessionStorage.getItem("redirectPath");
  if (window.location.pathname === storedRedirectPath) {
    window.sessionStorage.removeItem("redirectPath");
  }
  const redirectPath = storedRedirectPath || "/dashboard";

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route from="/*" element={<Navigate to={redirectPath} />} />
        <Route path="/dashboardold" element={<AsyncLandingPage />} />
        <Route path="/dashboard" element={<AsyncLandingPageNew />} />
        <Route path="/actions/*" element={<AsyncMyActionsPage />} />
        {ability.can("update", "Incident") && (
          <Route
            path={`/${responseWorkflowName.lower}s/*`}
            element={<AsyncIncidentRoutes />}
          />
        )}
        {ability.can("update", "Incident") && (
          <Route path="/workflow/*" element={<AsyncIncidentRoutes />} />
        )}
        {ability.can("update", "Obligation") && (
          <Route path="/obligations/*" element={<AsyncObligationRoutes />} />
        )}
        {ability.can("update", "Task") && (
          <Route path="/tasks/*" element={<AsyncTaskRoutes />} />
        )}
        {ability.can("update", "Playbook") && (
          <Route path="/playbooks/*" element={<AsyncPlaybookRoutes />} />
        )}
        <Route path="/evidence/*" element={<AsyncEvidenceRoutes />} />
        <Route path="/playground/*" element={<AsyncPlayground />} />
        <Route path="/reports/*" element={<AsyncReports />} />
        <Route path="/messaging/*" element={<AsyncMessaging />} />
        <Route path="/news/*" element={<AsyncNews />} />
        <Route path="/compliance/*" element={<AsyncComplianceRoutes />} />,
        {canEditSchema && (
          <Route path="/dataOptions/*" element={<AsyncSchemaRoutes />} />
        )}
        {canEditSchema && (
          <Route path="/data/*" element={<AsyncSchemaRoutes />} />
        )}
        {canEditSchema && (
          <Route path="/schema/*" element={<AsyncSchemaRoutes />} />
        )}
        {isAdmin && ability.can("update", "User") && (
          <Route path="/users/*" element={<AsyncUserRoutes />} />
        )}
        {ability.can("update", "Role") && (
          <Route path="/roles/*" element={<AsyncRoleRoutes />} />
        )}
        {isAdmin && [
          <Route
            path="/organization/*"
            key="organization"
            element={<AsyncOrganization />}
          />,
          <Route
            path="/notifications/*"
            key="notifications"
            element={<AsyncNotifications />}
          />,
          <Route
            path="/datasets/*"
            key="datasets"
            element={<AsyncDatasets />}
          />,
          <Route
            path="/integrations/*"
            key="integrations"
            element={<IntegrationRoutes />}
          />,
          <Route path="/api/*" key="api" element={<AsyncApiKeys />} />,
        ]}
        <Route path="/userProfile/*" element={<AsyncUserProfile />} />
        <Route path="/compliance" element={<AsyncComplianceRoutes />} />
        <Route path="*" element={<Navigate to={redirectPath} replace />} />
      </Routes>
    </Suspense>
  );
};

export default PlatformRoutes;

import {ALERT} from "../environment";
import {isFunction, isString} from "underscore";

export const handleError = (error, handler) => {
  console.error(error);
  ALERT.error(error.message);
  if (isFunction(handler)) handler(false);
};

export const handleSubscriptionError = (error, whichSubscription) => {
  if (isString(error?.message)) {
    const match = error.message.match(/\d+/);
    if (match) {
      console.error(
        `Error: ${whichSubscription} websocket closed with event ${parseInt(match[0], 10)}`,
      );
      return;
    }
  }

  console.error(error);
};

import React from "react";
import {InputAdornment} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useFormState} from "react-hook-form";
import CustomTextField from "../forms/CustomTextField";
import {useAuthStyles} from "./AuthStyles";

const NewPasswordFields = () => {
  const {classes} = useAuthStyles();
  const {errors} = useFormState();

  return (
    <>
      <CustomTextField
        name="newPassword"
        label="Please create a new password"
        type="password"
        required
        errorText={
          errors?.newPassword?.message ??
          "A new password of at least 10 characters is required"
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon className={classes.adornmentIcon} />
            </InputAdornment>
          ),
        }}
      />
      <CustomTextField
        name="confirmNewPassword"
        label="Please confirm your new password"
        type="password"
        required
        errorText={
          errors?.confirmNewPassword?.message ??
          "A new password of at least 10 characters is required"
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon className={classes.adornmentIcon} />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default NewPasswordFields;

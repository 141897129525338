import {gql} from "@apollo/client";

export const UPDATE_ZOOM_INTEGRATION = gql`
  mutation UpdateZoomIntegration($isEnabled: Boolean) {
    updateZoomIntegration(data: {isEnabled: $isEnabled}) {
      name
      configuration
    }
  }
`;

export const DELETE_ZOOM_INTEGRATION = gql`
  mutation DeleteZoomIntegration {
    deleteZoomIntegration {
      name
      configuration
    }
  }
`;

export const SET_ZOOM_ACCESS_TOKEN = gql`
  mutation SetZoomAccessTokens($code: String!) {
    setZoomAccessTokens(code: $code) {
      name
      configuration
    }
  }
`;

import {useState} from "react";
import {useMutation} from "@apollo/client";
import {isEmpty} from "underscore";
import dayjs from "dayjs";
import {UPDATE_USER_METADATA} from "../../graphql/userQueries";
import {useUser} from "../user/Context/UserProvider";

export const useTourStorage = ({tourId}) => {
  const [tourLoaded, setTourLoaded] = useState(false);
  const [completedSections, setCompletedSections] = useState([]);

  const {user} = useUser();
  const tourData = user?.metadata?.tours;

  const [saveMetadata, {loading: isUpdatingUserMetadata}] =
    useMutation(UPDATE_USER_METADATA);

  const addCompletedSection = (sectionName) => {
    if (!typeof sectionName === "string") {
      throw new Error("sectionName must be string");
    }
    setCompletedSections((prev) => [...new Set([...prev, sectionName])]);
  };

  const loadCompletedSections = () => {
    if (!tourId) {
      console.error("could not load, no current tourId present in context");
      return;
    }
    if (tourData) {
      const completedSectionsFromStorage =
        tourData?.[tourId]?.completedSections;
      if (
        completedSectionsFromStorage &&
        !isEmpty(completedSectionsFromStorage)
      ) {
        setCompletedSections(completedSectionsFromStorage);
      }
    }
    setTourLoaded(true);
  };

  const updateTourProgress = (tourId, completedSections) => {
    if (!tourId) {
      console.error("could not update progress, no tourId provided");
      return;
    }

    const metadata = user?.metadata ?? {};
    const tourStorage = metadata?.tours ?? {};

    const newMetadata = {
      ...metadata,
      tours: {
        ...tourStorage,
        [tourId]: {
          completedSections,
          paused: tourStorage?.[tourId]?.paused,
          updated: dayjs().toISOString(),
        },
      },
    };

    saveMetadata({variables: {id: user?.id, metadata: newMetadata}});
  };

  const permanentlyPauseTour = async (pauseTour = false) => {
    if (!tourId) {
      console.error("could not update progress, no tourId provided");
      return;
    }

    const metadata = user?.metadata ?? {};
    const tourStorage = metadata?.tours ? {...metadata?.tours} : {};

    if (tourStorage?.[tourId]?.paused === pauseTour) return;

    tourStorage[tourId] = {...tourStorage[tourId], paused: pauseTour};
    await saveMetadata({
      variables: {id: user?.id, metadata: {...metadata, tours: tourStorage}},
    });
  };

  const saveProgress = () => {
    if (!isEmpty(completedSections)) {
      updateTourProgress(tourId, completedSections);
    }
  };

  const resetTourProgress = (tourId) => updateTourProgress(tourId, []);

  return {
    tourLoaded,
    setTourLoaded,
    tourPermanentlyPaused: tourData?.[tourId]?.paused,
    completedSections,
    setCompletedSections,
    loadCompletedSections,
    addCompletedSection,
    saveProgress,
    permanentlyPauseTour,
    resetTourProgress,
    isUpdatingUserMetadata,
  };
};

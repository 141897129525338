import React from "react";
import PropTypes from "prop-types";
import EastIcon from "@mui/icons-material/East";

const NextIcon = ({id = "next"}) => (
  <EastIcon
    fontSize="small"
    sx={{verticalAlign: "middle", marginBottom: "2px"}}
    id={id}
  />
);

NextIcon.propTypes = {
  id: PropTypes.string,
};

export default NextIcon;

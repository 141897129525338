import React from "react";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import {useFilterButtonStyles} from "./buttonStyles";
import {isUndefined} from "underscore";

const FilterButton = ({children, isSelected, id, onClick}) => {
  if (isUndefined(children) || isUndefined(onClick)) return null;
  const {classes} = useFilterButtonStyles();

  return (
    <Button
      onClick={onClick}
      variant={isSelected ? "contained" : undefined}
      size="small"
      color={isSelected ? "primary" : "inherit"}
      disableRipple
      className={classes.filterButton}
      id={`${id}-button`}
    >
      {children}
    </Button>
  );
};

FilterButton.propTypes = {
  children: PropTypes.any,
  isSelected: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default FilterButton;

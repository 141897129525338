import React from "react";
import PropTypes from "prop-types";
import StyledTooltip from "../tooltips/StyledTooltip";
import {
  CheckOutlined,
  CloseOutlined,
  HourglassFullOutlined,
} from "@mui/icons-material";
import {makeStyles} from "tss-react/mui";

export const threeStateToText = ({state, trueText, falseText, thirdText}) =>
  state === true ? trueText ?? "Approved" :
  state === false ? falseText ?? "Rejected" :
  thirdText ?? "Pending";

export const useThreeStateIconStyles = makeStyles()(
  (theme, {green, inline}) => ({
    icon: {
      fill: green ? "green" : undefined,
      verticalAlign: inline ? "middle" : undefined,
      marginBottom: inline ? theme.spacing(0.5) : undefined,
      marginLeft: inline ? theme.spacing(0.25) : undefined,
    },
  }),
);

const ThreeStateIcon = ({state, trueText, falseText, thirdText, inline}) => {
  const {classes} = useThreeStateIconStyles({
    green: state === true,
    inline: inline,
  });
  const tooltipTitle = threeStateToText({
    state: state,
    trueText: trueText,
    falseText: falseText,
    thirdText: thirdText,
  });
  let icon = null;
  const id = tooltipTitle.toLowerCase();
  switch (state) {
    case true:
      icon = <CheckOutlined id={`${id}-icon`} className={classes.icon} />;
      break;
    case false:
      icon = (
        <CloseOutlined
          id={`${id}-icon`}
          color="error"
          className={classes.icon}
        />
      );
      break;
    default:
      icon = (
        <HourglassFullOutlined
          id={`${id}-icon`}
          color="primary"
          className={classes.icon}
        />
      );
  }

  return <StyledTooltip title={tooltipTitle}>{icon}</StyledTooltip>;
};

ThreeStateIcon.propTypes = {
  state: PropTypes.bool,
  trueText: PropTypes.string,
  falseText: PropTypes.string,
  thirdText: PropTypes.string,
  inline: PropTypes.bool,
};

export default ThreeStateIcon;

import {gql} from "@apollo/client";

const CORE_CHAT_FIELDS = gql`
  fragment CoreChatFields on ChatMessage {
    id
    message
    createdAt
    user {
      id
      fullName
      email
    }
  }
`;

const SUBSCRIBE_TO_INCIDENT_CHAT_MESSAGES = gql`
  ${CORE_CHAT_FIELDS}
  subscription SubscribeToIncidentChatMessages($incidentId: Int!) {
    subscribeToIncidentChatMessages(incidentId: $incidentId) {
      ...CoreChatFields
    }
  }
`;

const SUBSCRIBE_TO_ACTION_CHAT_MESSAGES = gql`
  ${CORE_CHAT_FIELDS}
  subscription SubscribeToActionChatMessages(
    $actionId: Int!
    $currentLatestId: Int
  ) {
    subscribeToActionChatMessages(
      actionId: $actionId
      currentLatestId: $currentLatestId
    ) {
      ...CoreChatFields
    }
  }
`;

export {
  CORE_CHAT_FIELDS,
  SUBSCRIBE_TO_INCIDENT_CHAT_MESSAGES,
  SUBSCRIBE_TO_ACTION_CHAT_MESSAGES,
};

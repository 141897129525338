import React from "react";
import PropTypes from "prop-types";
import {CircularProgress} from "@mui/material";

export const ButtonText = ({loading, text}) =>
  loading ? <CircularProgress size={24} /> : text;

ButtonText.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
};
